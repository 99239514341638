import React from "react"
import Button from "../../components/fundamentals/button"

type colorInputProps = {
  name: string
  color: string
  onClick: any
}

const colorInput: React.FC<colorInputProps> = ({ name, color, onClick }) => {
  return (
    <div className="flex flex-col ">
      <div className="flex items-center ">
        <div className="w-[210px]"></div>
        <div>Choose Color</div>
      </div>
      <div className="flex items-center">
        <div className="w-[210px]">{name}: </div>
        <Button
          style={{
            background: color || 'unset',
            border: '1px solid gray',
            width: "150px",
            height: "30px",
          }}
          onClick={onClick}
          variant={"primary"}
        ></Button>
        <div className="pl-[20px]">{color || 'not set yet'}</div>
      </div>
    </div>
  )
}

export default colorInput
