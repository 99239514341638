import moment from "moment"
import React, { useMemo } from "react"
import { getColor } from "../../../utils/color"
import Tooltip from "../../atoms/tooltip"
import CustomerAvatarItem from "../../molecules/customer-avatar-item"
import Table from "../../molecules/table"

const customerSubscriptionLogTableColumns = () => {
  const formatDuration = (value) => {
    return <div>{value}</div>
  }
  const columns = useMemo(
    () => [
      {
        Header: "recurring",
        accessor: "is_recurring",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index}>{value ? "Yes" : "No"}</Table.Cell>
        ),
      },
      {
        Header: "duration",
        accessor: "duration",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index}>{formatDuration(value)}</Table.Cell>
        ),
      },
      {
        Header: "start_date",
        accessor: "subscription_start_date",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index}>
            <Tooltip content={moment(value).format("YYYY-MM-DD HH:mm")}>
              {moment(value).format("YYYY-MM-DD")}
            </Tooltip>
          </Table.Cell>
        ),
      },
      {
        Header: "end_date",
        accessor: "subscription_end_date",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index}>
            <Tooltip content={moment(value).format("YYYY-MM-DD HH:mm")}>
              {moment(value).format("YYYY-MM-DD")}
            </Tooltip>
          </Table.Cell>
        ),
      },
      {
        Header: "created_at",
        accessor: "created_at",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index}>
            <Tooltip content={moment(value).format("YYYY-MM-DD HH:mm")}>
              {moment(value).format("YYYY-MM-DD")}
            </Tooltip>
          </Table.Cell>
        ),
      },
    ],
    []
  )

  return [columns]
}

export default customerSubscriptionLogTableColumns
