import { RouteComponentProps } from "@reach/router"
import { useAdminDeleteDiscount, useAdminDiscount } from "medusa-react"
import Medusa from "../../../services/api"
import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import Spinner from "../../../components/atoms/spinner"
import Breadcrumb from "../../../components/molecules/breadcrumb"
import DeletePrompt from "../../../components/organisms/delete-prompt"
import RawJSON from "../../../components/organisms/raw-json"
import useNotification from "../../../hooks/use-notification"
import medusaRequest from "../../../services/request"
import { getErrorMessage } from "../../../utils/error-messages"
import Conditions from "./conditions"
import Configurations from "./configurations"
import General from "./general"

const Edit: React.FC<RouteComponentProps<{ id: string }>> = ({ id }) => {
  // const { discount, isLoading } = useAdminDiscount(id!, undefined, {
  //   enabled: !!id,
  // })
  const expandFields = [
    "rule",
    "parent_discount",
    "regions",
    "rule.conditions",
    // "rule.conditions.product_variants",
    // "rule.conditions.products",
  ].join(",")
  const { data: discount, refetch, isLoading } = useQuery(
    `fetch-discount-custom-${id}`,
    async function () {
      const path = `/admin/discounts/${id}?expand=${expandFields}`
      const { data } = await medusaRequest("GET", path)

      // Expanding "rule.conditions.product_variants", "rule.conditions.product_types" together in get Discount will cause unexpected error
      // Cause: `PostgreSQL's Max Identifier Length Is 63 Bytes`, which cause left join table alias which over 63 characters to be truncated
      // Backend error: table name "Discount__rule__conditions_Discount__rule__conditions__product_" specified more than once
      // Difficult to override Medusa build sql left join query logic
      // Workaround: for each condition, call get condition api one more time and expand "product_variants", "products", "product_types"
      const discountId = data?.discount?.id
      const conditions = data?.discount?.rule?.conditions
      if (conditions) {
        for (let i = 0; i < conditions.length; i++) {
          const conditionId = conditions[i]?.id
          const {
            data: expandedConditionData,
          } = await Medusa.discounts.conditions.retrieve(
            discountId,
            conditionId,
            {
              expand: ["product_variants", "products", "product_types"].join(
                ","
              ),
            }
          )
          conditions[i] = {
            ...expandedConditionData?.discount_condition,
            ...conditions[i],
          }
        }
      }
      return data?.discount
    },
    { enabled: !!id }
  )

  const [showDelete, setShowDelete] = useState(false)
  const deleteDiscount = useAdminDeleteDiscount(id!)
  const notification = useNotification()

  const handleDelete = () => {
    deleteDiscount.mutate(undefined, {
      onSuccess: () => {
        notification("Success", "Discount deleted", "success")
      },
      onError: (error) => {
        notification("Error", getErrorMessage(error), "error")
      },
    })
  }

  return (
    <div className="pb-xlarge">
      {showDelete && (
        <DeletePrompt
          handleClose={() => setShowDelete(!showDelete)}
          onDelete={async () => handleDelete()}
          successText="Discount deleted"
          confirmText="Yes, delete"
          text="Are you sure you want to delete this discount?"
          heading="Delete discount"
        />
      )}

      <Breadcrumb
        currentPage="Add Discount"
        previousBreadcrumb="Discount"
        previousRoute="/a/discounts"
      />
      {isLoading || !discount ? (
        <div className="h-full flex items-center justify-center">
          <Spinner variant="secondary" />
        </div>
      ) : (
        <div className="flex flex-col gap-y-4">
          <General discount={discount} refetch={refetch} />
          <Configurations discount={discount} refetch={refetch} />
          <Conditions discount={discount} refetch={refetch} />
          {/* <RawJSON data={discount} title="Raw discount" /> */}
        </div>
      )}
    </div>
  )
}

export default Edit
