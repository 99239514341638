import moment from "moment"
import React, { useMemo } from "react"
import Tooltip from "../../atoms/tooltip"

const useCollectionTableColumn = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
        Cell: ({ row: { original } }) => {
          return <div className="flex items-center">{original.title}</div>
        },
      },
      {
        Header: "Handle",
        accessor: "handle",
        Cell: ({ cell: { value } }) => <div>/{value}</div>,
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => (
          <Tooltip content={moment(value).format("YYYY-MM-DD HH:mm")}>
            {moment(value).format("YYYY-MM-DD")}
          </Tooltip>
        ),
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        Cell: ({ cell: { value } }) => (
          <Tooltip content={moment(value).format("YYYY-MM-DD HH:mm")}>
            {moment(value).format("YYYY-MM-DD")}
          </Tooltip>
        ),
      },
      {
        Header: "Products",
        accessor: "products",
        Cell: ({ cell: { value } }) => {
          return <div>{value?.length || "-"}</div>
        },
      },
    ],
    []
  )

  return [columns]
}

export default useCollectionTableColumn
