import React, { useEffect, useState } from "react"
import Spinner from "../../../../../../components/atoms/spinner"
import Modal from "../../../../../../components/molecules/modal"
import useQueryFilters from "../../../../../../hooks/use-query-filters"
import {
  AddConditionSelectorProps,
  DiscountConditionOperator,
} from "../../../../types"
import { useDiscountForm } from "../../form/discount-form-context"
import { defaultQueryProps } from "../shared/common"
import ConditionOperator from "../shared/condition-operator"
import {
  ProductVariantRow,
  ProductVariantsHeader,
  useProductVariantColumns,
} from "../shared/product-variant"
import { SelectableTable } from "../shared/selectable-table"
import AddConditionFooter from "./add-condition-footer"
import { useAdminProductVariants } from "../../../../../../hooks/product-variant/queries"

const AddProductVariantConditionSelector = ({
  onClose,
}: AddConditionSelectorProps) => {
  const params = useQueryFilters(defaultQueryProps)
  const { conditions } = useDiscountForm()

  const [items, setItems] = useState(conditions.product_variants?.items || [])
  const [operator, setOperator] = useState<DiscountConditionOperator>(
    conditions.product_variants.operator
  )

  const { isLoading, count, productVariants } = useAdminProductVariants(
    params.queryObject,
    {
      keepPreviousData: true,
    }
  )

  const changed = (values: string[]) => {
    const oldSelectedItems = items.filter((item) => values.includes(item.id))
    const oldSelectedValues = oldSelectedItems.map((i) => i.id)
    const newSelectedVariants =
      productVariants?.filter(
        (variant) =>
          !oldSelectedValues.includes(variant.id) && values.includes(variant.id)
      ) || []

    setItems([
      ...newSelectedVariants.map((variant) => ({
        id: variant.id,
        label: variant.title,
      })),
      ...oldSelectedItems,
    ])
  }

  const columns = useProductVariantColumns()

  return (
    <>
      <Modal.Content isLargeModal={true}>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <ConditionOperator value={operator} onChange={setOperator} />
            <SelectableTable
              options={{
                enableSearch: true,
                immediateSearchFocus: true,
                searchPlaceholder: "Search product variants...",
              }}
              resourceName="Product Variants"
              totalCount={count || 0}
              selectedIds={items.map((i) => i.id)}
              data={productVariants}
              columns={columns}
              isLoading={isLoading}
              onChange={changed}
              renderRow={ProductVariantRow}
              renderHeaderGroup={ProductVariantsHeader}
              {...params}
            />
          </>
        )}
      </Modal.Content>
      <Modal.Footer isLargeModal>
        <AddConditionFooter
          type="product_variants"
          items={items}
          onClose={onClose}
          operator={operator}
        />
      </Modal.Footer>
    </>
  )
}

export default AddProductVariantConditionSelector
