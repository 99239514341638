import { capitalize } from "lodash"
import { ProductOption } from "../../types/shared"

export const useGridColumns = (product, isEditing, isSubscription) => {
  const defaultFields = [
    // { header: "Title", field: "title" },
    { header: "SKU", field: "sku" },
    // { header: "EAN", field: "ean" },
    { header: "Inventory", field: "inventory_quantity" },
  ]

  if (isEditing) {
    const validOptions: any = []
    product.options.forEach((option) => {
      if (isSubscription && option.title === ProductOption.DURATION) {
        validOptions.push(option)
      } else if (!isSubscription && option.title !== ProductOption.DURATION) {
        validOptions.push(option)
      }
    })
    const optionColumns = validOptions.map((o) => ({
      header: o.title,
      field: "options",
      formatter: (variantOptions) => {
        const displayVal = variantOptions.find((val) => val.option_id === o.id)
        return displayVal?.value || " - "
      },
    }))
    return [
      ...optionColumns,
      {
        header: "Prices",
        field: "prices",
        formatter: (prices) => prices.length > 0 ? `${prices?.[0]?.currency_code?.toUpperCase()} ${(prices?.[0]?.amount/100)?.toFixed(2)}` : '',
      },
      ...defaultFields,
    ]
  } else {
    return [
      {
        header: "Variant",
        field: "options",
        formatter: (value) => {
          const options = value.map((v) => {
            if (v.value) {
              return capitalize(v.value)
            }
            return capitalize(v)
          })

          return options.join(" / ")
        },
        readOnly: true,
        headCol: true,
      },
      ...defaultFields,
    ]
  }
}
