import { navigate } from "gatsby"
import { useAdminCreateDiscount } from "medusa-react"
import { useDiscountForm } from "./discount-form-context"
import { DiscountFormValues, formValuesToCreateDiscountMapper } from "./mappers"

export const useFormActions = () => {
  const createDiscount = useAdminCreateDiscount()

  const { conditions } = useDiscountForm()

  const onSaveAsInactive = async (values: DiscountFormValues) => {
    // if no discount condition, display error in admin frontend
    const dataToSubmit = formValuesToCreateDiscountMapper(values, conditions)
    if (
      !dataToSubmit.rule.conditions ||
      dataToSubmit.rule.conditions.length === 0
    ) {
      throw Error("No conditions")
    }

    // at least set one of start date or end date, else display error in admin frontend
    if (!dataToSubmit.starts_at && !dataToSubmit.ends_at) {
      throw Error("At Least One Date Required")
    }

    // if starts_at is not set, set it to current date
    if (!dataToSubmit.starts_at) {
      dataToSubmit.starts_at = new Date()
    }

    await createDiscount.mutateAsync(
      {
        ...dataToSubmit,
        is_disabled: true,
      },
      {
        onSuccess: () => {
          navigate("/a/discounts")
        },
      }
    )
  }

  const onSaveAsActive = async (values: DiscountFormValues) => {
    // if no discount condition, display error in admin frontend
    const dataToSubmit = formValuesToCreateDiscountMapper(values, conditions)
    if (
      !dataToSubmit.rule.conditions ||
      dataToSubmit.rule.conditions.length === 0
    ) {
      throw Error("No conditions")
    }

    // at least set one of start date or end date, else display error in admin frontend
    if (!dataToSubmit.starts_at && !dataToSubmit.ends_at) {
      throw Error("At Least One Date Required")
    }

    // if starts_at is not set, set it to current date
    if (!dataToSubmit.starts_at) {
      dataToSubmit.starts_at = new Date()
    }

    await createDiscount.mutateAsync(
      {
        ...dataToSubmit,
        is_disabled: false,
      },
      {
        onSuccess: () => {
          navigate("/a/discounts")
        },
      }
    )
  }

  return {
    onSaveAsInactive,
    onSaveAsActive,
  }
}
