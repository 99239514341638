import React from "react"
import Button from "../../fundamentals/button"
import Modal from "../../molecules/modal"
import moment from "moment"

type ExportModalProps = {
  handleClose: () => void
  onSubmit?: () => void
  loading: boolean
  title: string
  exportFilter: any
}

const ExportModal: React.FC<ExportModalProps> = ({
  handleClose,
  title,
  loading,
  onSubmit,
  exportFilter,
}) => {
  return (
    <Modal handleClose={handleClose}>
      <Modal.Body>
        <Modal.Header handleClose={handleClose}>
          <span className="inter-xlarge-semibold">{title}</span>
        </Modal.Header>
        <Modal.Content>
          <div className="flex inter-small-semibold mb-2">Current filters</div>
          <div className="flex mb-4 inter-small-regular text-grey-50">
            {Object.keys(exportFilter).length === 0 && `None`}
          </div>
          <div className="flex mb-4 inter-small-regular text-grey-50">
            {typeof exportFilter?.metadata?.sandbox_payment !== "undefined" &&
              `Sandbox Payment: ${exportFilter?.metadata?.sandbox_payment?.toString()}`}
          </div>
          <div className="flex mb-4 inter-small-regular text-grey-50">
            {typeof exportFilter?.metadata?.is_testing !== "undefined" &&
              `Is Testing: ${exportFilter?.metadata?.is_testing?.toString()}`}
          </div>
          <div className="flex mb-4 inter-small-regular text-grey-50">
            {exportFilter?.created_at?.gt &&
              `After: ${moment(exportFilter?.created_at?.gt)?.format(
                "YYYY-MM-DD HH:mm"
              )}`}
          </div>
          <div className="flex mb-4 inter-small-regular text-grey-50">
            {exportFilter?.created_at?.lt &&
              `Before: ${moment(exportFilter?.created_at?.lt)?.format(
                "YYYY-MM-DD HH:mm"
              )}`}
          </div>
          {/* TODO: Add filtering */}
          {/* <div className="flex mb-4 inter-small-regular text-grey-50">
            Initialize an export of your data
          </div> */}
        </Modal.Content>
        <Modal.Footer>
          <div className="w-full flex justify-end">
            <Button
              variant="ghost"
              size="small"
              onClick={handleClose}
              className="mr-2"
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              variant="primary"
              size="small"
              onClick={onSubmit}
            >
              Export
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}

export default ExportModal
