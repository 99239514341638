import React, { useEffect, useState } from "react"
import BodyCard from "../../../../components/organisms/body-card"
import { useProductForm } from "../form/product-form-context"
import Checkbox from "../../../../components/atoms/checkbox"
import Textarea from "../../../../components/molecules/textarea"
import clsx from "clsx"

const TermsConditions = ({ product }) => {
  const { register, metadata, setMetadata } = useProductForm()

  const [productTermsConditions, setProductTermsConditions] = useState(() => {
    return ""
  })

  const [productPrivacyPolicy, setProductPrivacyPolicy] = useState(() => {
    return ""
  })

  const [
    displayGeneralTermsConditions,
    setDisplayGeneralTermsConditions,
  ] = useState(false)

  const [
    displayGeneralPrivacyPolicy,
    setDisplayGeneralPrivacyPolicy,
  ] = useState(false)

  const [
    displayProductTermsConditions,
    setDisplayProductTermsConditions,
  ] = useState(false)

  const [
    displayProductPrivacyPolicy,
    setDisplayProductPrivacyPolicy,
  ] = useState(false)

  const [
    displayAcceptPromotionNotifications,
    setDisplayAcceptPromotionNotifications,
  ] = useState(false)

  /* 
    Fix display value not same with useForm value (cause submitting wrong value)
    use useEffect instead to dynamic change display value base on useForm value
  */
  useEffect(() => {
    setProductTermsConditions(metadata?.product_terms_conditions ?? "")
  }, [metadata.product_terms_conditions])

  useEffect(() => {
    setProductPrivacyPolicy(metadata.product_privacy_policy ?? "")
  }, [metadata.product_privacy_policy])

  useEffect(() => {
    setDisplayGeneralTermsConditions(metadata.display_general_terms_conditions)
  }, [metadata.display_general_terms_conditions])

  useEffect(() => {
    setDisplayProductTermsConditions(metadata.display_product_terms_conditions)
  }, [metadata.display_product_terms_conditions])

  useEffect(() => {
    setDisplayGeneralPrivacyPolicy(metadata.display_general_privacy_policy)
  }, [metadata.display_general_privacy_policy])

  useEffect(() => {
    setDisplayProductPrivacyPolicy(metadata.display_product_privacy_policy)
  }, [metadata.display_product_privacy_policy])

  useEffect(() => {
    setDisplayAcceptPromotionNotifications(
      metadata.display_accept_promotion_notifications
    )
  }, [metadata.display_accept_promotion_notifications])

  const handleProductPrivacyPolicyChange = (event) => {
    const value = event.target.value
    // setProductPrivacyPolicy(value)
    // metadata.product_privacy_policy = value
    setMetadata({
      ...metadata,
      product_privacy_policy: value,
    })
  }

  const handleProductTermsConditionsChange = (event) => {
    const value = event.target.value
    // setProductTermsConditions(value)
    // metadata.product_terms_conditions = value
    setMetadata({
      ...metadata,
      product_terms_conditions: value,
    })
  }

  const handleToggleDisplayTnc = () => {
    // setDisplayGeneralTermsConditions((v) => !v)
    // setDisplayProductTermsConditions((v) => !v)
    setMetadata({
      ...metadata,
      display_general_terms_conditions: !metadata.display_general_terms_conditions,
      display_product_terms_conditions: !metadata.display_product_terms_conditions,
    })
  }

  const handleToggleDisplayPrivacy = () => {
    // setDisplayGeneralPrivacyPolicy((v) => !v)
    // setDisplayProductPrivacyPolicy((v) => !v)
    setMetadata({
      ...metadata,
      display_general_privacy_policy: !metadata.display_general_privacy_policy,
      display_product_privacy_policy: !metadata.display_product_privacy_policy,
    })
  }

  const handleDisplayAcceptPromotionNotifications = (event) => {
    const value = event.target.checked
    // setDisplayAcceptPromotionNotifications(value)
    // metadata.display_accept_promotion_notifications = value
    setMetadata({
      ...metadata,
      display_accept_promotion_notifications: value,
    })
  }

  return (
    <BodyCard title="T&C">
      <Checkbox
        name="display_accept_promotion_notifications"
        label="Display Accept promotion notifications"
        checked={displayAcceptPromotionNotifications}
        onChange={handleDisplayAcceptPromotionNotifications}
        ref={register}
        className="mb-2"
      />
      <div className="flex item-center gap-x-1.5 mb-2">
        <Checkbox
          name="display_general_terms_conditions"
          label="Display General T&C"
          className="pr-[30px] min-w-[300px]"
          checked={displayGeneralTermsConditions}
          onChange={handleToggleDisplayTnc}
          ref={register}
        />
        <Checkbox
          name="display_product_terms_conditions"
          label="Display Product T&C"
          checked={displayProductTermsConditions}
          onChange={handleToggleDisplayTnc}
          ref={register}
        />
      </div>

      <div className="flex item-center gap-x-1.5 mb-2">
        <Checkbox
          name="display_general_privacy_policy"
          label="Display General Privacy Policy"
          className="pr-[30px] min-w-[300px]"
          checked={displayGeneralPrivacyPolicy}
          onChange={handleToggleDisplayPrivacy}
          ref={register}
        />
        <Checkbox
          name="display_product_privacy_policy"
          label="Display Product Privacy Policy"
          checked={displayProductPrivacyPolicy}
          onChange={handleToggleDisplayPrivacy}
          ref={register}
        />
      </div>

      <div className="mb-2">Product T&C</div>
      <Textarea
        name="product_terms_conditions"
        label=""
        rows={3}
        placeholder="Product T&C"
        value={productTermsConditions}
        onChange={handleProductTermsConditionsChange}
        ref={register}
        className="mb-2"
      />
      <div className="mb-2">Product Privacy Policy</div>
      <Textarea
        name="product_privacy_policy"
        label=""
        rows={3}
        placeholder="Product Privacy Policy"
        value={productPrivacyPolicy}
        onChange={handleProductPrivacyPolicyChange}
        ref={register}
        className="mb-2"
      />
    </BodyCard>
  )
}

export default TermsConditions
