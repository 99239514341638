import { ProductVariant } from "@medusajs/medusa"
import React, { useMemo } from "react"
import { Column, HeaderGroup, Row } from "react-table"
import SortingIcon from "../../../../../../components/fundamentals/icons/sorting-icon"
import ImagePlaceholder from "../../../../../../components/fundamentals/image-placeholder"
import StatusIndicator from "../../../../../../components/fundamentals/status-indicator"
import Table from "../../../../../../components/molecules/table"

const getProductStatusVariant = (status: string) => {
  switch (status) {
    case "proposed":
      return "warning"
    case "published":
      return "success"
    case "rejected":
      return "danger"
    case "draft":
    default:
      return "default"
  }
}

export const ProductVariantRow = ({ row }: { row: Row<ProductVariant> }) => {
  return (
    <Table.Row {...row.getRowProps()}>
      {row.cells.map((cell) => {
        return (
          <Table.Cell {...cell.getCellProps()}>
            {cell.render("Cell")}
          </Table.Cell>
        )
      })}
    </Table.Row>
  )
}

export const ProductVariantsHeader = ({
  headerGroup,
}: {
  headerGroup: HeaderGroup<ProductVariant>
}) => {
  return (
    <Table.HeadRow {...headerGroup.getHeaderGroupProps()}>
      {headerGroup.headers.map((col) => (
        <Table.HeadCell
          className="w-[100px]"
          {...col.getHeaderProps(col.getSortByToggleProps())}
        >
          {col.render("Header")}
        </Table.HeadCell>
      ))}
    </Table.HeadRow>
  )
}

export const useProductVariantColumns = () => {
  const columns = useMemo<Column<ProductVariant>[]>(() => {
    return [
      {
        Header: () => (
          <div className="flex items-center gap-1 min-w-[150px]">
            Title <SortingIcon size={16} />
          </div>
        ),
        accessor: "title",
        Cell: ({ row: { original } }) => {
          return (
            <div className="flex items-center">
              <div className="flex flex-col">
                <span>{original.title}</span>
              </div>
            </div>
          )
        },
      },
      {
        Header: () => (
          <div className="flex items-center gap-1 min-w-[200px]">
            Product Title <SortingIcon size={16} />
          </div>
        ),
        accessor: "product title",
        Cell: ({ row: { original } }) => {
          return (
            <div className="flex items-center">
              <div className="flex flex-col">
                <span>{original.product.title}</span>
              </div>
            </div>
          )
        },
      },
      {
        Header: () => (
          <div className="flex items-center gap-1">
            Status <SortingIcon size={16} />
          </div>
        ),
        accessor: "status",
        Cell: ({ row: { original } }) => (
          <StatusIndicator
            title={`${original.product.status
              .charAt(0)
              .toUpperCase()}${original.product.status.slice(1)}`}
            variant={getProductStatusVariant(original.product.status)}
          />
        ),
      },
    ]
  }, [])

  return columns
}
