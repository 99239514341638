import { AdminGetProductsVariantsParams } from "@medusajs/medusa"
import { Response } from "@medusajs/medusa-js"
import { useQuery } from "react-query"
import Medusa from "../../services/api"
import { useMedusa } from "medusa-react"
import { UseQueryOptionsWrapper } from "medusa-react/dist/types"

// export type AdminProductVariantListRes = PaginatedResponse & {
//   products: (ProductVariant)[]
// }

export const useAdminProductVariants = (
  query?: AdminGetProductsVariantsParams,
  options?
) => {
  // const { client } = useMedusa()
  const { data, ...rest } = useQuery(
    "product-variant" + query ? JSON.stringify(query) : "",
    async function () {
      console.log("useAdminProductVariants query", query)
      const data = await Medusa.products.variants.listSearch(query)
      data["count"] = data.data.data[1]
      data["productVariants"] = data.data.data[0]
      delete data.data
      // console.log(productVariantResp, data)
      return data
    },
    options
  )
  return { ...data, ...rest } as const
}
