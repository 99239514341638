export const titleCase = (str) => {
  return str.slice(0, 1).toUpperCase() + str.slice(1)
}

export const buildTitle = (str) => {
  const arr = str.split("_")
  let title = ""
  arr.forEach((str) => {
    title += titleCase(str) + " "
  })
  return title
}
