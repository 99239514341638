import React from "react"

import StatusDot from "../../../components/fundamentals/status-indicator"

export const SubscriptionStatusComponent = ({ status }) => {
  switch (status) {
    case "active":
      return <StatusDot title="Active (Auto-renew)" variant="success" />
    case "inactive":
      return <StatusDot title="Expired" variant="danger" />
    case "stop":
      return <StatusDot title="Active (No-renew)" variant="warning" />
    default:
      return null
  }
}
