import * as RadixPopover from "@radix-ui/react-popover"
import React, { ReactNode, useEffect, useRef, useState } from "react"
import { useWindowDimensions } from "../../../hooks/use-window-dimensions"
import Button from "../../fundamentals/button"
import CheckIcon from "../../fundamentals/icons/check-icon"

type ProductFilterPopoverProps = {
  submitFilters: () => void
  // clearFilters: () => void
  triggerElement: ReactNode
  products: any[]
  tempState: any
}

const ProductFilterPopover: React.FC<ProductFilterPopoverProps> = ({
  submitFilters,
  // clearFilters,
  triggerElement,
  products,
  tempState,
  children,
}) => {
  const { height } = useWindowDimensions()
  const ref = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [heightStyle, setHeightStyle] = useState({
    maxHeight: height,
  })

  const [checked, setChecked] = useState([])

  const onApplyProductFilter = () => {
    const variantList = []
    const productList = products?.filter((p) => checked.includes(p.id))
    productList.forEach((p) => variantList.push(...p.variants.map((v) => v.id)))
    // alert(JSON.stringify(variantList,null,2))
    submitFilters({
      ...tempState,
      variant_id: { open: true, filter: variantList },
      offset: 0,
    })
  }

  const onClearProductFilter = () => {
    setChecked([])
    submitFilters({
      ...tempState,
      variant_id: { open: true, filter: [] },
      offset: 0,
    })
  }

  useEffect(() => {
    setHeightStyle({
      maxHeight: height - ref?.current?.getBoundingClientRect().y - 50,
    })
  }, [ref])

  const onSubmit = () => {
    setIsOpen(false)
    // submitFilters()
    onApplyProductFilter()
  }

  const onClear = () => {
    setIsOpen(false)
    // clearFilters()
    onClearProductFilter()
  }

  return (
    <RadixPopover.Root open={isOpen} onOpenChange={setIsOpen}>
      <RadixPopover.Trigger ref={ref} asChild>
        {triggerElement}
      </RadixPopover.Trigger>
      <RadixPopover.Content
        sideOffset={8}
        style={heightStyle}
        className="bg-grey-0 overflow-y-auto rounded-rounded shadow-dropdown max-w-[272px] py-4"
      >
        <div className="flex px-4 pb-4 border-b border-grey-20">
          <Button
            size="small"
            tabIndex={-1}
            className="mr-2 border border-grey-20"
            variant="ghost"
            onClick={() => onClear()}
          >
            Clear
          </Button>
          <Button
            tabIndex={-1}
            variant="primary"
            className="w-44 justify-center"
            size="small"
            onClick={() => onSubmit()}
          >
            Apply
          </Button>
        </div>
        {products?.map((p) => (
          <div
            key={p.id}
            onClick={() => {
              if (checked.includes(p.id)) {
                setChecked(checked.filter((c) => c !== p.id))
              } else {
                const newChecked = [...checked, p.id]
                setChecked(newChecked)
              }
            }}
            className="cursor-pointer flex hover:bg-grey-20 my-1 py-1.5 pl-6 items-center rounded"
          >
            <div
              className={`w-5 h-5 flex justify-center text-grey-0 border-grey-30 border mr-2 rounded-base ${
                checked.includes(p.id) && "bg-violet-60"
              }`}
            >
              <span className="self-center">
                {checked.includes(p.id) && <CheckIcon size={16} />}
              </span>
            </div>
            <div className="max-w-[200px]">{p.title}</div>
          </div>
        ))}
      </RadixPopover.Content>
    </RadixPopover.Root>
  )
}

export default ProductFilterPopover
