import React, { useEffect, useState } from "react"
import { Controller } from "react-hook-form"
import useNotification from "../../../hooks/use-notification"
import Medusa from "../../../services/api"
import { getErrorMessage } from "../../../utils/error-messages"
import Button from "../../fundamentals/button"
import InputField from "../../molecules/input"
import Modal from "../../molecules/modal"
import Select from "../../molecules/select"

type EditUserModalProps = {
  handleClose: () => void
  user: {
    id: string
    first_name: string
    last_name: string
    email: string
  }
  onSubmit: () => void
  vendorList: any[]
}

const EditUserModal: React.FC<EditUserModalProps> = ({
  handleClose,
  user,
  onSubmit,
  vendorList,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState(user.email)
  const [first_name, setFirstName] = useState(user.first_name)
  const [last_name, setLastName] = useState(user.last_name)
  // const [vendorList, setVendorList] = useState([])
  const [vendorId, setVendorId] = useState(user.vendor_id)
  const notification = useNotification()

  // const fetchVendorList = async () => {
  //   const { data } = await Medusa.vendor.list()
  //   if (data.code === 200) {
  //     setVendorList(
  //       data.data.map(({ id, name }) => ({
  //         label: name,
  //         value: id,
  //       }))
  //     )
  //   }
  // }
  // useEffect(() => {
  //   fetchVendorList()
  // }, [])

  const submit = () => {
    setIsLoading(true)
    Medusa.users
      .update(user.id, {
        first_name,
        last_name,
        vendor_id: vendorId,
      })
      .then((resp) => {
        if (resp?.data?.code === 401) {
          notification("Error", resp?.data?.msg || "Unknown Error", "error")
        } else {
          onSubmit()
        }
      })
      .catch((err) => notification("Error", getErrorMessage(err), "error"))

    handleClose()
  }

  return (
    <Modal handleClose={handleClose}>
      <Modal.Body>
        <Modal.Header handleClose={handleClose}>
          <span className="inter-xlarge-semibold">Edit User</span>
        </Modal.Header>
        <Modal.Content>
          <div className="w-full flex mb-4">
            <InputField
              label="First Name"
              placeholder="First name..."
              onChange={(e) => setFirstName(e.target.value)}
              value={first_name}
              className="mr-4"
            />
            <InputField
              label="Last Name"
              placeholder="Last name..."
              onChange={(e) => setLastName(e.target.value)}
              value={last_name}
            />
          </div>
          <InputField label="Email" disabled value={email} />
          <div className="my-4">
            <Select
              label="Choose Team"
              placeholder=""
              clearSelected={true}
              options={vendorList}
              value={vendorList.find((v) => v.value === vendorId)}
              onChange={(e) => {
                setVendorId(e?.value || null)
              }}
            />
          </div>
        </Modal.Content>
        <Modal.Footer>
          <div className="w-full flex justify-end">
            <Button
              variant="ghost"
              size="small"
              onClick={handleClose}
              className="mr-2"
            >
              Cancel
            </Button>
            <Button
              loading={isLoading}
              variant="primary"
              size="small"
              onClick={submit}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}

export default EditUserModal
