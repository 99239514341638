import { RouteComponentProps } from "@reach/router"
import React from "react"
import { usePagination, useTable } from "react-table"
import Table from "../../molecules/table"
import customerSubscriptionLogTableColumns from "./customer-subscription-log-column"

const CustomerSubscriptionLogTable = ({ logs }: React.FC<RouteComponentProps>) => {
  const [columns] = customerSubscriptionLogTableColumns()
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: logs || [],
    },
    usePagination
  )

  return (
    <div className="w-full overflow-y-auto flex flex-col justify-between min-h-[250px] h-full ">
      <Table {...getTableProps()}>
        <Table.Head>
          {headerGroups?.map((headerGroup, index) => (
            <Table.HeadRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((col, headerIndex) => (
                <Table.HeadCell {...col.getHeaderProps()}>
                  {col.render("Header")}
                </Table.HeadCell>
              ))}
            </Table.HeadRow>
          ))}
        </Table.Head>
        <Table.Body {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <Table.Row
                color={"inherit"}
                {...row.getRowProps()}
                className="group"
              >
                {row.cells.map((cell, index) => {
                  return cell.render("Cell", { index })
                })}
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </div>
  )
}

export default CustomerSubscriptionLogTable
