import { useAdminUpdateVariant } from "medusa-react"
import React, { useEffect, useMemo, useState } from "react"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import Button from "../../../../components/fundamentals/button"
import Input from "../../../../components/molecules/input"
import BodyCard from "../../../../components/organisms/body-card"
import Select from "../../../../components/molecules/select"
import Checkbox from "../../../../components/atoms/checkbox"
import IconTooltip from "../../../../components/molecules/icon-tooltip"
import { ArrayInput } from "./ArrayInput"
import ArrowDownIcon from "../../../../components/fundamentals/icons/arrow-down-icon"
import ArrowUpIcon from "../../../../components/fundamentals/icons/arrow-up-icon"
import useNotification from "../../../../hooks/use-notification"

const Surveys = ({ isEdit, product }) => {
  const updateVariant = useAdminUpdateVariant(product?.id)
  const notification = useNotification()

  const allQuestionType = [
    "Text",
    "Number",
    "Dropdown List",
    "Email",
    "Phone Number",
  ].map((qt) => ({
    label: qt,
    value: qt,
  }))

  const defaultSurveyValues = {
    surveys:
      [
        {
          // ...product?.variants?.[0]?.surveys,
          questions: product?.variants?.[0]?.surveys?.[0]?.questions?.map(
            (q) => ({
              ...q,
              type: allQuestionType?.find((qt) => qt?.value === q?.type) || "",
              options: !q?.options
                ? []
                : q?.options?.split(",").map((opt) => ({ value: opt })) || [],
            })
          ),
        },
      ] || [],
  }

  const {
    control,
    register,
    reset,
    watch,
    handleSubmit,
    formState,
    trigger,
  } = useForm({
    defaultValues: defaultSurveyValues,
  })

  const {
    fields: questionFields,
    append: appendQuestion,
    remove: removeQuestion,
    move: moveQuestion,
  } = useFieldArray({
    control,
    name: "surveys[0].questions",
    keyName: "keyId",
  })

  console.log("questionFields", questionFields)

  const [collapsedQ, setCollapseQ] = useState<Set<string>>(
    new Set(questionFields.map((field) => field.id || ""))
  )
  const [selectedVariant, setSelectedVariant] = useState<any>(null)
  const handleUpdateAllVariants = async (data) => {
    // console.log('submit data', data)
    // return
    const d = {
      surveys: [
        {
          ...data?.surveys?.[0],
          questions: data?.surveys?.[0]?.questions?.map((q) => ({
            ...q,
            type: q?.type?.value || "",
            options: q?.options?.map(({ value }) => value).join(",") || "",
          })),
        },
      ],
    }
    console.log("newData", d)
    await updateVariant.mutateAsync({
      variant_id: product?.variants?.[0]?.id,
      ...d,
    })
    // return
    window.location.reload()

    // updateVariant.mutate(
    //   { variant_id: selectedVariant?.id, ...data },
    //   {
    //     onSuccess: () => {
    //       // notification("Success", "Successfully update variant", "success")
    //       setSelectedVariant(null)
    //     },
    //     onError: (err) => {
    //       // notification("Error", getErrorMessage(err), "error")
    //     },
    //   }
    // )
  }

  const watchSurveys = watch("surveys")

  return (
    <BodyCard title="Surveys" subtitle="Add custom question fields for product">
      {product?.variants?.length > 0 ? (
        <>
          <input
            ref={register()}
            type="hidden"
            name={`surveys[0].id`}
            defaultValue={product?.variants?.[0]?.surveys?.[0]?.id}
          />
          {questionFields.map((field, index) => (
            <div
              key={field.keyId}
              className="mb-4 p-4 bg-slate-100 rounded-rounded"
            >
              <div className="grid grid-cols-3 mb-2">
                <div className="flex gap-2 items-center">
                  <Button
                    variant="primary"
                    onClick={() => {
                      if (!field.id) {
                        return
                      }
                      const id = field.id
                      if (collapsedQ.has(id)) {
                        setCollapseQ(
                          (prev) => new Set([...prev].filter((x) => x !== id))
                        )
                      } else {
                        setCollapseQ((prev) => new Set(prev.add(id)))
                      }
                    }}
                  >
                    Question {index + 1}
                  </Button>
                  <div>{watchSurveys?.[0]?.questions?.[index]?.name}</div>
                </div>
                <div className="flex justify-center gap-2">
                  <Button
                    variant="primary"
                    onClick={() => {
                      if (index === 0) {
                        return
                      }
                      trigger()
                      moveQuestion(index, index - 1)
                    }}
                  >
                    <ArrowUpIcon size={"20"} />
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      if (index === questionFields.length - 1) {
                        return
                      }
                      trigger()
                      moveQuestion(index, index + 1)
                    }}
                  >
                    <ArrowDownIcon size={"20"} />
                  </Button>
                </div>
                <div className="flex justify-end">
                  <Button
                    variant="danger"
                    onClick={() => {
                      removeQuestion(index)
                    }}
                  >
                    remove question
                  </Button>
                </div>
              </div>
              <div
                className={`w-full mt-4 grid medium:grid-cols-2 grid-cols-1 gap-y-base gap-x-xsmall ${
                  collapsedQ.has(field.id) ? "hidden" : ""
                }`}
              >
                <input
                  ref={register()}
                  type="hidden"
                  name={`surveys[0].questions[${index}].id`}
                  defaultValue={field.id}
                />
                <Input
                  label="Question"
                  placeholder="Question Name"
                  name={`surveys[0].questions[${index}].name`}
                  ref={register({ required: true })}
                  defaultValue={field.name}
                  required
                />
                <Controller
                  rules={{ required: true }}
                  defaultValue={field.type}
                  control={control}
                  name={`surveys[0].questions[${index}].type`}
                  render={({ onChange, value }) => {
                    return (
                      <Select
                        label="Question Type"
                        placeholder="Text / Number..."
                        options={allQuestionType}
                        value={value}
                        onChange={onChange}
                        required
                      />
                    )
                  }}
                />
                <div
                  className={`col-span-2 ${
                    watchSurveys?.[0]?.questions?.[index]?.type?.value ===
                    "Dropdown List"
                      ? ""
                      : "hidden"
                  }`}
                >
                  <ArrayInput
                    control={control}
                    index={index}
                    register={register}
                  />
                </div>

                <input
                  name={`surveys[0].questions[${index}].order`}
                  ref={register()}
                  type={"hidden"}
                  defaultValue={index}
                />
                <input
                  //   label="Group"
                  //   placeholder="1"
                  name={`surveys[0].questions[${index}].group`}
                  ref={register()}
                  //   defaultValue={field.group}
                  defaultValue={"1"}
                  type={"hidden"}
                />
                <div className="flex items-center gap-x-2">
                  <Checkbox
                    label="Increase with product quantity?"
                    name={`surveys[0].questions[${index}].quantity_related`}
                    ref={register}
                    defaultChecked={field.quantity_related}
                  />
                  <IconTooltip
                    content={
                      "Whether number of this question should be the same as quantity of product customer want to purchase"
                    }
                  />
                </div>
                <div className="flex items-center gap-x-2">
                  <Checkbox
                    label="Required?"
                    name={`surveys[0].questions[${index}].required`}
                    ref={register}
                    defaultChecked={field.required}
                  />
                  <IconTooltip
                    content={"Whether this is a required question"}
                  />
                </div>
              </div>
            </div>
          ))}
          <div className="mb-4">
            <Button
              variant="primary"
              onClick={() => {
                appendQuestion({
                  name: "",
                  type: "",
                  options: [],
                  quantity_related: false,
                  required: false,
                })
              }}
            >
              add question
            </Button>
          </div>
          <Button
            onClick={handleSubmit(handleUpdateAllVariants, (e) => {
              console.log("invalid", e)
              notification("Error", "At least 1 input is invalid", "error")
              setCollapseQ(new Set())
            })}
            type="submit"
            className="w-[127px]"
            size="small"
            variant="primary"
          >
            Save
          </Button>
        </>
      ) : (
        <div>
          <h6 className="inter-base-semibold text-blue-600">
            You have to create at least 1 variant to create surveys (custom
            questions)
          </h6>
        </div>
      )}
    </BodyCard>
  )
}

export default Surveys
