import React, { useEffect, useState } from "react"
import Button from "../../../components/fundamentals/button"
import InputField from "../../../components/molecules/input"
import Modal from "../../../components/molecules/modal"
import useNotification from "../../../hooks/use-notification"
import Medusa from "../../../services/api"
import Select from "../../../components/molecules/select"

type EditVendorModalProps = {
  handleClose: () => void
  vendorList: any[]
}

const EditVendorModal: React.FC<EditVendorModalProps> = ({ handleClose, vendorList }) => {
  const [vendorId, setVendorId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const notification = useNotification()

  const handleDelete = (e) => {
    e.preventDefault()
    if (!vendorId) {
      notification("Error", "Need to choose a team to delete", "error")
      return
    }
    Medusa.vendor.delete(vendorId).then((resp) => {
      if (resp?.data?.code === 401) {
        notification("Error", resp?.data?.msg || "Unknown Error", "error")
      } else {
        notification("Success", "team deleted", "success")
      }
      handleClose()
    })
  }

  return (
    <Modal handleClose={handleClose}>
      <Modal.Body>
        <Modal.Header handleClose={handleClose}>
          <span className="inter-xlarge-semibold">Delete Team</span>
        </Modal.Header>
        <Modal.Content>
          <div className="my-4 flex items-center gap-4">
            <Select
              fullWidth={true}
              label="Team"
              placeholder=""
              options={vendorList}
              value={vendorList.find((v) => v.value === vendorId)}
              onChange={(e) => {
                setVendorId(e?.value || null)
              }}
            />
          </div>
        </Modal.Content>
        <Modal.Footer>
          <div className="flex w-full h-8 justify-end">
            <Button
              variant="ghost"
              className="mr-2 w-32 text-small justify-center"
              size="large"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              loading={isLoading}
              size="large"
              className="w-32 text-small justify-center"
              variant="primary"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}

export default EditVendorModal
