import {
  useAdminCreateVariant,
  useAdminDeleteVariant,
  useAdminUpdateVariant,
  useAdminUpdateProduct,
} from "medusa-react"
import React, { useState } from "react"
import VariantEditor from "../../domain/products/details/variants/variant-editor"
import { buildOptionsMap } from "../../domain/products/product-form/utils"
import useImperativeDialog from "../../hooks/use-imperative-dialog"
import useNotification from "../../hooks/use-notification"
import { getErrorMessage } from "../../utils/error-messages"
import DuplicateIcon from "../fundamentals/icons/duplicate-icon"
import EditIcon from "../fundamentals/icons/edit-icon"
import TrashIcon from "../fundamentals/icons/trash-icon"
import ArrowUpIcon from "../fundamentals/icons/arrow-up-icon"
import ArrowDownIcon from "../fundamentals/icons/arrow-down-icon"
import GridInput from "../molecules/grid-input"
import Table from "../molecules/table"
import { useGridColumns } from "./use-grid-columns"
import Modal from "../molecules/modal"
import moment from "moment"

const VariantGrid = ({
  product,
  variants,
  edit,
  onVariantsChange,
  isSubscription = false,
}) => {
  const [isDuplicate, setIsDuplicate] = useState(false)
  const [selectedVariant, setSelectedVariant] = useState<{
    prices: any[]
    origin_country: string
    options: any[]
    [k: string]: any
  } | null>(null)

  const [viewPriceHistoryVariant, setViewPriceHistoryVariant] = useState<any>(
    null
  )

  const questions = product?.variants?.[0]?.surveys?.[0]?.questions
  const survey_id = product?.variants?.[0]?.surveys?.[0]?.id

  const createVariant = useAdminCreateVariant(product?.id)
  const updateVariant = useAdminUpdateVariant(product?.id)
  const deleteVariant = useAdminDeleteVariant(product?.id)
  const updateProduct = useAdminUpdateProduct(product?.id)

  const notification = useNotification()
  const dialog = useImperativeDialog()

  const columns = useGridColumns(product, edit, isSubscription)

  const handleChange = (index, field, value) => {
    const newVariants = [...variants]
    newVariants[index] = {
      ...newVariants[index],
      [field]: value,
    }

    onVariantsChange(newVariants)
  }

  const getDisplayValue = (variant, column) => {
    const { formatter, field } = column
    return formatter ? formatter(variant[field]) : variant[field]
  }

  const handleUpdateVariant = (data) => {
    updateVariant.mutate(
      { variant_id: selectedVariant?.id, ...data },
      {
        onSuccess: () => {
          notification("Success", "Successfully update variant", "success")
          setSelectedVariant(null)
        },
        onError: (err) => {
          notification("Error", getErrorMessage(err), "error")
        },
      }
    )
  }

  const handleDeleteVariant = async (variant) => {
    const shouldDelete = await dialog({
      heading: "Delete product variant",
      text: "Are you sure?",
    })

    if (shouldDelete) {
      return deleteVariant.mutate(variant.id)
    }
  }

  const handleDuplicateVariant = async (variant) => {
    createVariant.mutate(
      { ...variant },
      {
        onSuccess: () => {
          notification("Success", "Successfully created variant", "success")
          setSelectedVariant(null)
        },
        onError: (err) => {
          notification("Error", getErrorMessage(err), "error")
        },
      }
    )
  }

  const moveItem = (data, from, to) => {
    data.splice(to, 0, data.splice(from, 1)[0])
    return data
  }

  const handleReorderVariant = (v, action) => {
    const vIndex = variants.findIndex((el) => el.id === v.id)
    let newVariants
    if (action === "up") {
      if (vIndex === 0) {
        notification("Error", "Already Top", "error")
        return
      }
      newVariants = moveItem(variants, vIndex, vIndex - 1).map(({ id }) => ({
        id,
      }))
    }
    if (action === "down") {
      if (vIndex === variants.length - 1) {
        notification("Error", "Already Last", "error")
        return
      }
      newVariants = moveItem(variants, vIndex, vIndex + 1).map(({ id }) => ({
        id,
      }))
    }
    if (!newVariants) {
      return
    }
    updateProduct.mutate(
      {
        variants: newVariants,
      },
      {
        onSuccess: () => {
          notification("Success", "Successfully reorder variants", "success")
        },
        onError: (err) => {
          notification("Error", getErrorMessage(err), "error")
        },
      }
    )
  }

  const editVariantActions = (variant) => {
    return [
      {
        label: "Edit",
        icon: <EditIcon size={20} />,
        onClick: () => setSelectedVariant(variant),
      },
      {
        label: "Duplicate",
        icon: <DuplicateIcon size={20} />,
        onClick: () => {
          setSelectedVariant(variant)
          setIsDuplicate(true)
        },
      },
      {
        label: "Delete",
        icon: <TrashIcon size={20} />,
        onClick: () => handleDeleteVariant(variant),
        variant: "danger",
      },
      {
        label: "Up",
        icon: <ArrowUpIcon size={20} />,
        onClick: () => handleReorderVariant(variant, "up"),
      },
      {
        label: "Down",
        icon: <ArrowDownIcon size={20} />,
        onClick: () => handleReorderVariant(variant, "down"),
      },
      {
        label: "View Price History",
        icon: <EditIcon size={20} />,
        onClick: () => setViewPriceHistoryVariant(variant),
      },
    ]
  }

  return (
    <>
      <Table>
        <Table.Head>
          <Table.HeadRow>
            {columns.map((col, i) => (
              <Table.HeadCell className="w-[100px] px-2 py-4" key={i}>
                {col.header}
              </Table.HeadCell>
            ))}
          </Table.HeadRow>
        </Table.Head>
        <Table.Body>
          {variants.map((variant, i) => {
            return (
              <Table.Row
                key={i}
                color={"inherit"}
                actions={edit && editVariantActions(variant)}
              >
                {columns.map((col, j) => {
                  return (
                    <Table.Cell key={j}>
                      {edit || col.readOnly ? (
                        <div className="px-2 py-4 truncate">
                          {getDisplayValue(variant, col)}
                        </div>
                      ) : (
                        <GridInput
                          key={j}
                          value={variant[col.field]}
                          onChange={({ currentTarget }) =>
                            handleChange(i, col.field, currentTarget.value)
                          }
                        />
                      )}
                    </Table.Cell>
                  )
                })}
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      {selectedVariant && (
        <VariantEditor
          variant={selectedVariant}
          onCancel={() => setSelectedVariant(null)}
          onSubmit={isDuplicate ? handleDuplicateVariant : handleUpdateVariant}
          optionsMap={buildOptionsMap(product, selectedVariant)}
          title="Edit variant"
          isSubscription={isSubscription}
          survey_id={survey_id}
          defaultDescription={product?.description}
          defaultDescriptionRows={product?.metadata?.descriptions}
        />
      )}
      {viewPriceHistoryVariant && viewPriceHistoryVariant?.price_history && (
        <Modal handleClose={() => setViewPriceHistoryVariant(null)}>
          <Modal.Body>
            <Modal.Header handleClose={() => setViewPriceHistoryVariant(null)}>
              <h2 className="inter-xlarge-semibold">Variant Price History</h2>
            </Modal.Header>
            <Modal.Content>
              <div>
                <div className="grid grid-cols-3 border-b mb-2">
                  <div>Date</div>
                  <div>Price</div>
                  <div>Action User</div>
                </div>
                {viewPriceHistoryVariant?.price_history
                  ?.sort((a, b) => {
                    const atime = moment(a?.created_at).unix() || 0
                    const btime = moment(b?.created_at).unix() || 0
                    return btime - atime
                  })
                  ?.map((p) => (
                    <div className="grid grid-cols-3 mb-1" key={p.id}>
                      <div>
                        {moment(p?.created_at).format("YYYY-MM-DD HH:mm")}
                      </div>
                      <div>{`${p?.currency?.toUpperCase()} ${
                        p?.amount / 100
                      }`}</div>
                      <div>{p?.act_by}</div>
                    </div>
                  ))}
              </div>
            </Modal.Content>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export default VariantGrid
