import React, { useEffect, useState } from "react"
import Button from "../../../components/fundamentals/button"
import InputField from "../../../components/molecules/input"
import Modal from "../../../components/molecules/modal"
import useNotification from "../../../hooks/use-notification"
import Medusa from "../../../services/api"
import Select from "../../../components/molecules/select"

type CreateUserModalProps = {
  handleClose: () => void
}

const CreateUserModal: React.FC<CreateUserModalProps> = ({ handleClose }) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const notification = useNotification()

  const handleSubmit = (e) => {
    e.preventDefault()

    setIsLoading(true)

    const values = {
      email,
      password,
    }

    Medusa.users.create(values).then((resp) => {
      if (resp?.data?.code === 401) {
        notification("Error", resp?.data?.msg || "Unknown Error", "error")
      } else {
        notification("Success", "user created", "success")
      }
      setIsLoading(false)
      handleClose()
    })
  }

  return (
    <Modal handleClose={handleClose}>
      <Modal.Body>
        <Modal.Header handleClose={handleClose}>
          <span className="inter-xlarge-semibold">Create User</span>
        </Modal.Header>
        <Modal.Content>
          <div className="flex flex-col gap-2">
            <InputField
              label="Email"
              placeholder=""
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <InputField
              autoComplete="new-password"
              label="Password"
              placeholder=""
              type={"password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </Modal.Content>
        <Modal.Footer>
          <div className="flex w-full h-8 justify-end">
            <Button
              variant="ghost"
              className="mr-2 w-32 text-small justify-center"
              size="large"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              loading={isLoading}
              size="large"
              className="w-32 text-small justify-center"
              variant="primary"
              onClick={handleSubmit}
            >
              Create
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}

export default CreateUserModal
