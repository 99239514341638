import React from "react"
import Button from "../../components/fundamentals/button"

type logoImageProps = {
  tabName: string
  name: string
  handleClick: any
}

const logoImage: React.FC<logoImageProps> = ({
  tabName,
  name,
  handleClick,
}) => {
  return (
    <Button
      variant="ghost"
      size="medium"
      className="my-[6px] px-[30px] py-[5px] text-grey-50 text-violet-60"
      style={{
        width: "160px",
        border: tabName === name ? "solid 1px #7c3aed" : "solid 1px #333",
        color: tabName === name ? "#7c3aed" : "#333",
      }}
      onClick={handleClick}
    >
      {name}
    </Button>
  )
}

export default logoImage
