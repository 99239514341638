import React, { useEffect, useState } from "react"
import { Controller, useFieldArray } from "react-hook-form"
import BodyCard from "../../../../components/organisms/body-card"
import { useProductForm } from "../form/product-form-context"
import Select from "../../../../components/molecules/select"
import { buildTitle } from "../../../../utils/string-helper"
import Medusa from "../../../../services/api"

const GrantDigitalAccess = ({ product }) => {
  const { control } = useProductForm()

  const [rules, setRules] = React.useState([])
  // console.log("rules", rules)

  const resourcesOptions = [
    { label: "Wordpress Article", value: "wp_article" },
    { label: "E-Mag", value: "e_mag" },
  ]
  const [currentResource, setCurrentResource] = useState(resourcesOptions[0])
  // console.log("currentResource", currentResource)
  const handleChangeResource = (value) => {
    // console.log(value)
    setCurrentResource(value)
  }

  const getRules = async () => {
    const data = await Medusa.rule.rules(currentResource.value)
    const d = data.data
    if (d.code === 200) {
      setRules(
        d.data.map((p) => ({
          policy_id: p.id,
          policy_name: p.name,
          value: p.id,
          label: p.name,
        }))
      )
    }
  }

  useEffect(() => {
    getRules()
  }, [currentResource])

  return (
    <BodyCard title="Grant Digital Access" forceDropdown={true}>
      <div>
        <Select
          value={currentResource}
          onChange={(value) => {
            handleChangeResource(value)
          }}
          placeholder="Placeholder"
          label="Digital resources"
          required
          options={resourcesOptions}
        />

        <Controller
          control={control}
          name="rules"
          render={({ onChange, value }) => {
            // convert rules data from database to select options
            const nvalue = value.map(({ policy_id, policy_name, label }) => ({
              policy_id: parseInt(policy_id),
              policy_name,
              label,
              value: parseInt(policy_id),
            }))
            return (
              <Select
                className="mt-4"
                label="Article subscription tags"
                placeholder="Placeholder"
                isMultiSelect={true}
                // hasSelectAll={false}
                clearSelected={true}
                required
                options={rules}
                value={nvalue}
                onChange={onChange}
              />
            )
          }}
        />
      </div>
    </BodyCard>
  )
}

export default GrantDigitalAccess
