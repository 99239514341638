import { Router, RouteComponentProps } from "@reach/router"
import clsx from "clsx"
import React, { useEffect, useState } from "react"
import BodyCard from "../../components/organisms/body-card"
import FileUploadModal from "../../components/organisms/file-upload-modal"
import useNotification from "../../hooks/use-notification"
import { getErrorMessage } from "../../utils/error-messages"
import LogoImage from "./logoImage"
import TabBtn from "./tabBtn"
import Textarea from "../../components/molecules/textarea"
import ColorInput from "./colorInput"
import { SketchPicker } from "react-color"
import Modal from "../../components/molecules/modal"
import { buildTitle } from "../../utils/string-helper"
import Medusa from "../../services/api"
import { ContentState, convertToRaw, EditorState } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import htmlToDraft from "html-to-draftjs"
import draftToHtml from "draftjs-to-html"
import { BlockPicker } from "react-color"
import { background } from "@storybook/theming"

const ReactColorComponent = ({
  expanded,
  onExpandEvent,
  onChange,
  currentState,
}) => {
  const { color } = currentState
  const stopPropagation = (event) => {
    event.stopPropagation()
  }
  const onChangeColor = (color) => {
    onChange("color", color.hex)
  }
  return (
    <div
      aria-haspopup="true"
      aria-expanded={expanded}
      aria-label="rdw-color-picker"
      className="cursor-pointer flex items-center mb-1.5 mx-1 relative"
    >
      <div onClick={onExpandEvent} className="border border-[#f1f1f1] px-1">
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAdgAAAHYBTnsmCAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAC4SURBVDiNpdIxakJBFIXhLyYgWImQFeg2xMYmq7ARFbS0kUSwt7KSrMjKJYjYaSpLm5gUbx6IPJg3euDCDHP/c+YOw5N6TejtYoo2djinBPVwxV+oE5pl4Rp+buC8lvAWgb/QwnvBWT2W/IJDQXJeHzGDT9nc+zvwhH5ZeIDGjck3qikwjML+gMWj8ByVGDwLzcMCOKpOaB4/AsMa27CepMJkr3sMJr+ycUop/4mrkHoJN9ikpD+lfykrOiqOY+x9AAAAAElFTkSuQmCC"
          alt=""
        />
      </div>
      {expanded ? (
        <div className="absolute z-10 top-9" onClick={stopPropagation}>
          <BlockPicker color={color} onChangeComplete={onChangeColor} />
        </div>
      ) : null}
    </div>
  )
}

const StorefrontConfigIndex: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    const getConfigs = async () => {
      const result = await Medusa.storefrontConfig.configs()
      const d = result.data
      if (d.code !== 200) {
        return
      }

      const configs = d.data
      if (configs.themes) {
        setThemes(configs.themes)
        setBrandLogo({
          url: themes.brand_logo,
          file: null,
        })
        setBrandLogoLight({
          url: themes.brand_logo_light,
          file: null,
        })
        setSubscriptionPlanIcon({
          url: themes.subscription_plan_icon,
          file: null,
        })
      }
      if (configs.termsConditions) {
        setTermsConditions(configs.termsConditions)
      }
      if (configs.privacyPolicy) {
        setPrivacyPolicy(configs.privacyPolicy)
      }
      if (configs.subscriptonPageConfig) {
        // setSubscriptonPageHeading(configs.subscriptonPageConfig?.heading)
        // setSubscriptonPageDescription(
        //   configs.subscriptonPageConfig?.description
        // )

        const blockHeading = htmlToDraft(configs.subscriptonPageConfig?.heading)
        const blockDescription = htmlToDraft(
          configs.subscriptonPageConfig?.description
        )

        const csHeading = ContentState.createFromBlockArray(
          blockHeading.contentBlocks,
          blockHeading.entityMap
        )

        const csDescription = ContentState.createFromBlockArray(
          blockDescription.contentBlocks,
          blockDescription.entityMap
        )

        setSubscriptonPageHeading(EditorState.createWithContent(csHeading))
        setSubscriptonPageDescription(
          EditorState.createWithContent(csDescription)
        )
      }
    }
    getConfigs()
  }, [])

  const notification = useNotification()

  const updateConfig = async (data) => {
    const result = await Medusa.storefrontConfig.update(data)
    const d = result.data
    if (d.code !== 200) {
      notification("Fail", "save fail", d)
      return
    }
    notification("Success", "save success", "success")
  }

  const TAB_NAME = {
    THEMES: "Themes",
    TERMS_CONDITIONS: "General T&C",
    POLICY: "Privacy Policy",
    SUBSCRIPTION_PAGE_CONFIG: "Subscription Page Config",
  }

  const LOGO_NAME = {
    BRAND: "brand logo",
    BRAND_LIGHT: "brand logo(light)",
    SUBSCRIPTION: "subscription plan icon",
  }

  const COLOR_KEY_NAME = {
    PRIMARY: "primary_color",
    PRIMARY_GRADIENT: "primary_color_gradient",
    ALTERNATIVE: "alternative_color",
    LIGHT: "light_color",
    BACKGROUND: "background_color",
    ACTION_BUTTON: "action_button_color",
    ACTION_BUTTON_GRADIENT: "action_button_color_gradient",
    ACTION_BUTTON_TEXT: "action_button_text_color",
    ACTION_BUTTON_HOVER: "action_button_hover_color",
    ACTION_BUTTON_HOVER_GRADIENT: "action_button_hover_color_gradient",
    ACTION_BUTTON_ACTIVE: "action_button_active_color",
    ACTION_BUTTON_ACTIVE_GRADIENT: "action_button_active_color_gradient",
    ACTION_BUTTON_DISABLED: "action_button_disabled_color",
  }

  const defaultLogo = {
    url: "",
    file: null,
  }
  const [uploadLogo, setUploadLogo] = useState(LOGO_NAME.BRAND)
  const [brandLogo, setBrandLogo] = useState(defaultLogo)
  const [brandLogoLight, setBrandLogoLight] = useState(defaultLogo)
  const [subscriptionPlanIcon, setSubscriptionPlanIcon] = useState(defaultLogo)

  const [termsConditions, setTermsConditions] = useState("")

  const [privacyPolicy, setPrivacyPolicy] = useState("")

  const [subscriptonPageHeading, setSubscriptonPageHeading] = useState<
    EditorState
  >()
  const [subscriptonPageDescription, setSubscriptonPageDescription] = useState<
    EditorState
  >()

  const [tabName, setTabName] = useState(TAB_NAME.THEMES)

  const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false)
  const [isLoadingProfilePicture, setIsLoadingProfilePicture] = useState(false)
  const [colorModalIsOpen, setColorModalIsOpen] = useState(false)
  const [currentColor, setCurrentColor] = useState("#ad0000")
  const [currentColorName, setCurrentColorName] = useState(
    COLOR_KEY_NAME.PRIMARY
  )

  const [themes, setThemes] = useState({
    primary_color: "#1e37a6",
    primary_color_gradient: "#1e37a6",
    alternative_color: "#00e2ff",
    light_color: "#ffffff",
    background_color: "#f3f4f5",
    action_button_color: "#e6c685",
    action_button_color_gradient: "#e6c685",
    action_button_text_color: "#ffffff",
    action_button_hover_color: "#caa967",
    action_button_hover_color_gradient: "#caa967",
    action_button_active_color: "#c79632",
    action_button_active_color_gradient: "#c79632",
    action_button_disabled_color: "rgba(60, 66,87,0.12)",
    brand_logo: "",
    brand_logo_light: "",
    subscription_plan_icon: "",
  })

  const onColorChange = (e) => {
    if (e.rgb.a === 1) {
      setCurrentColor(e.hex)
    } else {
      setCurrentColor(`rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`)
    }
  }

  const showColorModal = (name) => {
    setColorModalIsOpen(true)
    setCurrentColorName(name)
    setCurrentColor(themes[name])
  }

  const handleColorModalClose = () => {
    setColorModalIsOpen(false)
    // if (themes[currentColorName]) {
    //   themes[currentColorName] = currentColor
    //   setThemes({ ...themes })
    // }
    themes[currentColorName] = currentColor
    setThemes({ ...themes })
  }

  const handleTabClick = (name) => {
    setTabName(name)
  }

  const handleSave = async () => {
    const newHeading = draftToHtml(
      convertToRaw(subscriptonPageHeading?.getCurrentContent())
    ).replaceAll("\n", "")
    const newDescription = draftToHtml(
      convertToRaw(subscriptonPageDescription?.getCurrentContent())
    ).replaceAll("\n", "")
    const data = {
      themes: themes,
      terms_conditions: {
        description: termsConditions,
      },
      privacy_policy: {
        description: privacyPolicy,
      },
      subscription_page_config: {
        heading: newHeading,
        description: newDescription,
      },
    }
    console.log("save config", data)
    updateConfig(data)
  }

  const events = [
    {
      label: "Save",
      onClick: handleSave,
    },
  ]

  const handleFileUpload = async (files) => {
    const file = files[0]
    setUploadModalIsOpen(false)
    setIsLoadingProfilePicture(true)

    let url = ""
    await Medusa.uploads
      .create([file])
      .then(({ data }) => {
        const uploaded = data.uploads.map(({ url }) => url)
        url = uploaded[0]
      })
      .catch((err) => {
        notification("Error uploading images", getErrorMessage(err), "error")
        return
      })

    if (uploadLogo === LOGO_NAME.BRAND) {
      themes.brand_logo = url
    } else if (uploadLogo === LOGO_NAME.BRAND_LIGHT) {
      themes.brand_logo_light = url
    } else if (uploadLogo === LOGO_NAME.SUBSCRIPTION) {
      themes.subscription_plan_icon = url
    }
    setThemes({ ...themes })
    setIsLoadingProfilePicture(false)
  }

  const showUploadModal = (name) => {
    setUploadLogo(name)
    setUploadModalIsOpen(true)
  }
  return (
    <BodyCard
      title="Storefront Config"
      subtitle="Manage the Config of your Medusa store"
      events={events}
      className={"h-auto max-h-full"}
    >
      <div className="w-full flex  h-full">
        <div className="flex flex-col max-h-full border-r border-grey-30 pr-6">
          <TabBtn
            tabName={tabName}
            name={TAB_NAME.THEMES}
            handleClick={() => {
              handleTabClick(TAB_NAME.THEMES)
            }}
          />
          <TabBtn
            tabName={tabName}
            name={TAB_NAME.TERMS_CONDITIONS}
            handleClick={() => {
              handleTabClick(TAB_NAME.TERMS_CONDITIONS)
            }}
          />
          <TabBtn
            tabName={tabName}
            name={TAB_NAME.POLICY}
            handleClick={() => {
              handleTabClick(TAB_NAME.POLICY)
            }}
          />
          <TabBtn
            tabName={tabName}
            name={TAB_NAME.SUBSCRIPTION_PAGE_CONFIG}
            handleClick={() => {
              handleTabClick(TAB_NAME.SUBSCRIPTION_PAGE_CONFIG)
            }}
          />
        </div>
        <div className="w-full pl-6">
          {tabName === TAB_NAME.THEMES && (
            <div>
              <div className="w-full flex h-full gap-10">
                <LogoImage
                  img={themes.brand_logo}
                  name={LOGO_NAME.BRAND}
                  loading={
                    uploadLogo === LOGO_NAME.BRAND && isLoadingProfilePicture
                  }
                  handleClick={() => {
                    showUploadModal(LOGO_NAME.BRAND)
                  }}
                />
                <LogoImage
                  img={themes.brand_logo_light}
                  name={LOGO_NAME.BRAND_LIGHT}
                  loading={
                    uploadLogo === LOGO_NAME.BRAND_LIGHT &&
                    isLoadingProfilePicture
                  }
                  handleClick={() => {
                    showUploadModal(LOGO_NAME.BRAND_LIGHT)
                  }}
                />
                <LogoImage
                  img={themes.subscription_plan_icon}
                  name={LOGO_NAME.SUBSCRIPTION}
                  loading={
                    uploadLogo === LOGO_NAME.SUBSCRIPTION &&
                    isLoadingProfilePicture
                  }
                  handleClick={() => {
                    showUploadModal(LOGO_NAME.SUBSCRIPTION)
                  }}
                />
              </div>
              <div className="ml-2 mt-5 flex flex-col h-full gap-2">
                <div className="flex flex-wrap gap-x-5 gap-y-2 justify-between">
                  <ColorInput
                    name={buildTitle(COLOR_KEY_NAME.PRIMARY)}
                    color={themes[COLOR_KEY_NAME.PRIMARY]}
                    onClick={() => {
                      showColorModal(COLOR_KEY_NAME.PRIMARY)
                    }}
                  />
                  <ColorInput
                    name={buildTitle(COLOR_KEY_NAME.PRIMARY_GRADIENT)}
                    color={themes[COLOR_KEY_NAME.PRIMARY_GRADIENT]}
                    onClick={() => {
                      showColorModal(COLOR_KEY_NAME.PRIMARY_GRADIENT)
                    }}
                  />
                </div>
                <ColorInput
                  name={buildTitle(COLOR_KEY_NAME.ALTERNATIVE)}
                  color={themes[COLOR_KEY_NAME.ALTERNATIVE]}
                  onClick={() => {
                    showColorModal(COLOR_KEY_NAME.ALTERNATIVE)
                  }}
                />
                <ColorInput
                  name={buildTitle(COLOR_KEY_NAME.LIGHT)}
                  color={themes[COLOR_KEY_NAME.LIGHT]}
                  onClick={() => {
                    showColorModal(COLOR_KEY_NAME.LIGHT)
                  }}
                />
                <ColorInput
                  name={buildTitle(COLOR_KEY_NAME.BACKGROUND)}
                  color={themes[COLOR_KEY_NAME.BACKGROUND]}
                  onClick={() => {
                    showColorModal(COLOR_KEY_NAME.BACKGROUND)
                  }}
                />
                <div className="flex flex-wrap gap-x-5 gap-y-2 justify-between">
                  <ColorInput
                    name={buildTitle(COLOR_KEY_NAME.ACTION_BUTTON)}
                    color={themes[COLOR_KEY_NAME.ACTION_BUTTON]}
                    onClick={() => {
                      showColorModal(COLOR_KEY_NAME.ACTION_BUTTON)
                    }}
                  />
                  <ColorInput
                    name={buildTitle(COLOR_KEY_NAME.ACTION_BUTTON_GRADIENT)}
                    color={themes[COLOR_KEY_NAME.ACTION_BUTTON_GRADIENT]}
                    onClick={() => {
                      showColorModal(COLOR_KEY_NAME.ACTION_BUTTON_GRADIENT)
                    }}
                  />
                </div>
                <ColorInput
                  name={buildTitle(COLOR_KEY_NAME.ACTION_BUTTON_TEXT)}
                  color={themes[COLOR_KEY_NAME.ACTION_BUTTON_TEXT]}
                  onClick={() => {
                    showColorModal(COLOR_KEY_NAME.ACTION_BUTTON_TEXT)
                  }}
                />
                <div className="flex flex-wrap gap-x-5 gap-y-2 justify-between">
                  <ColorInput
                    name={buildTitle(COLOR_KEY_NAME.ACTION_BUTTON_HOVER)}
                    color={themes[COLOR_KEY_NAME.ACTION_BUTTON_HOVER]}
                    onClick={() => {
                      showColorModal(COLOR_KEY_NAME.ACTION_BUTTON_HOVER)
                    }}
                  />
                  <ColorInput
                    name={buildTitle(
                      COLOR_KEY_NAME.ACTION_BUTTON_HOVER_GRADIENT
                    )}
                    color={themes[COLOR_KEY_NAME.ACTION_BUTTON_HOVER_GRADIENT]}
                    onClick={() => {
                      showColorModal(
                        COLOR_KEY_NAME.ACTION_BUTTON_HOVER_GRADIENT
                      )
                    }}
                  />
                </div>
                <div className="flex flex-wrap gap-x-5 gap-y-2 justify-between">
                  <ColorInput
                    name={buildTitle(COLOR_KEY_NAME.ACTION_BUTTON_ACTIVE)}
                    color={themes[COLOR_KEY_NAME.ACTION_BUTTON_ACTIVE]}
                    onClick={() => {
                      showColorModal(COLOR_KEY_NAME.ACTION_BUTTON_ACTIVE)
                    }}
                  />
                  <ColorInput
                    name={buildTitle(
                      COLOR_KEY_NAME.ACTION_BUTTON_ACTIVE_GRADIENT
                    )}
                    color={themes[COLOR_KEY_NAME.ACTION_BUTTON_ACTIVE_GRADIENT]}
                    onClick={() => {
                      showColorModal(
                        COLOR_KEY_NAME.ACTION_BUTTON_ACTIVE_GRADIENT
                      )
                    }}
                  />
                </div>
                <ColorInput
                  name={buildTitle(COLOR_KEY_NAME.ACTION_BUTTON_DISABLED)}
                  color={themes[COLOR_KEY_NAME.ACTION_BUTTON_DISABLED]}
                  onClick={() => {
                    showColorModal(COLOR_KEY_NAME.ACTION_BUTTON_DISABLED)
                  }}
                />
              </div>
            </div>
          )}

          {tabName === TAB_NAME.TERMS_CONDITIONS && (
            <Textarea
              label=""
              rows={12}
              placeholder={TAB_NAME.TERMS_CONDITIONS}
              value={termsConditions}
              onChange={(e) => setTermsConditions(e.target.value)}
            />
          )}
          {tabName === TAB_NAME.POLICY && (
            <Textarea
              label=""
              rows={12}
              placeholder={TAB_NAME.POLICY}
              value={privacyPolicy}
              onChange={(e) => setPrivacyPolicy(e.target.value)}
            />
          )}
          {tabName === TAB_NAME.SUBSCRIPTION_PAGE_CONFIG && (
            <div className="w-full flex-col h-full gap-10">
              {/* <Textarea
                label=""
                rows={12}
                placeholder="Subscription page heading"
                value={subscriptonPageHeading}
                onChange={(e) => setSubscriptonPageHeading(e.target.value)}
              />
              <Textarea
                label=""
                rows={12}
                placeholder="Subscription page description"
                value={subscriptonPageDescription}
                onChange={(e) => setSubscriptonPageDescription(e.target.value)}
              /> */}
              {subscriptonPageHeading && subscriptonPageDescription && (
                <>
                  <div className="text-xl">Heading</div>
                  <div className="border">
                    <Editor
                      editorStyle={{ background: "#D3D3D3" }}
                      editorState={subscriptonPageHeading}
                      onEditorStateChange={setSubscriptonPageHeading}
                      toolbar={{
                        fontSize: {
                          className: "hidden",
                        },
                        colorPicker: { component: ReactColorComponent },
                      }}
                    />
                  </div>
                  <div className="text-xl mt-4">Description</div>
                  <div className="border">
                    <Editor
                      editorStyle={{ background: "#D3D3D3" }}
                      editorState={subscriptonPageDescription}
                      onEditorStateChange={setSubscriptonPageDescription}
                      toolbar={{
                        fontSize: {
                          className: "hidden",
                        },
                        colorPicker: { component: ReactColorComponent },
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {uploadModalIsOpen && (
        <FileUploadModal
          setFiles={handleFileUpload}
          filetypes={["image/png", "image/jpeg"]}
          handleClose={() => {
            setUploadModalIsOpen(false)
          }}
        />
      )}
      {colorModalIsOpen && (
        <Modal handleClose={handleColorModalClose} isLargeModal={false}>
          <Modal.Body>
            <Modal.Header handleClose={handleColorModalClose}>
              <span className="inter-xlarge-semibold">Choose Color</span>
            </Modal.Header>
            <Modal.Content>
              <SketchPicker
                width={500}
                color={currentColor}
                onChangeComplete={onColorChange}
              />
            </Modal.Content>
          </Modal.Body>
        </Modal>
      )}
    </BodyCard>
  )
}

const StorefrontConfig = () => {
  return (
    <Router>
      <StorefrontConfigIndex path="/" />
    </Router>
  )
}

export default StorefrontConfig
