import React, { useEffect, useState } from "react"
import BreadCrumb from "../../../components/molecules/breadcrumb"
import Medusa from "../../../services/api"
import BodyCard from "../../../components/organisms/body-card"
import InviteModal from "../../../components/organisms/invite-modal"
import UserIcon from "../../../components/fundamentals/icons/users-icon"
import EditIcon from "../../../components/fundamentals/icons/edit-icon"
import UserTable from "../../../components/templates/user-table"
import EditVendorModal from "./edit-vendor-modal"
import CreateVendorModal from "./create-vendor-modal"
import CreateUserModal from "./create-user-modal"

const Users: React.FC = () => {
  const [users, setUsers] = useState([])
  const [invites, setInvites] = useState([])
  const [shouldRefetch, setShouldRefetch] = useState(0)
  const [showInviteModal, setShowInviteModal] = useState(false)
  const [showCreateVendorModal, setShowCreateVendorModal] = useState(false)
  const [showDeleteVendorModal, setShowDeleteVendorModal] = useState(false)
  const [showCreateUserModal, setShowCreateUserModal] = useState(false)
  const [vendorList, setVendorList] = useState([])

  const fetchVendorList = async () => {
    const { data } = await Medusa.vendor.list()
    if (data.code === 200) {
      setVendorList(
        data.data.map(({ id, name }) => ({
          label: name,
          value: id,
        }))
      )
    }
  }

  const triggerRefetch = () => {
    setShouldRefetch((prev) => prev + 1)
  }

  useEffect(() => {
    Medusa.users
      .list()
      .then((res) => res.data)
      .then((userData) => {
        Medusa.invites
          .list()
          .then((res) => res.data)
          .then((inviteData) => {
            setUsers(userData.users)
            setInvites(inviteData.invites)
            fetchVendorList()
          })
      })
  }, [shouldRefetch])

  const actionables = [
    {
      label: "Create Team",
      onClick: () => setShowCreateVendorModal(true),
      icon: (
        <span className="text-grey-90">
          <EditIcon size={20} />
        </span>
      ),
    },
    {
      label: "Delete Team",
      onClick: () => setShowDeleteVendorModal(true),
      icon: (
        <span className="text-grey-90">
          <EditIcon size={20} />
        </span>
      ),
    },
    // {
    //   label: "Invite Users",
    //   onClick: () => setShowInviteModal(true),
    //   icon: (
    //     <span className="text-grey-90">
    //       <PlusIcon size={20} />
    //     </span>
    //   ),
    // },
    {
      label: "Create User",
      onClick: () => setShowCreateUserModal(true),
      icon: (
        <span className="text-grey-90">
          <UserIcon size={20} />
        </span>
      ),
    },
  ]

  return (
    <div className="flex flex-col h-full">
      <div className="w-full flex flex-col grow">
        <BreadCrumb
          previousRoute="/a/settings"
          previousBreadcrumb="Settings"
          currentPage="The Team"
        />
        <BodyCard
          title="The Team"
          subtitle="Manage users of your Medusa Store"
          actionables={actionables}
        >
          <div className="flex grow  flex-col pt-2">
            <UserTable
              users={users}
              invites={invites}
              triggerRefetch={triggerRefetch}
              vendorList={vendorList}
            />
          </div>
          <div className="inter-small-regular text-grey-50">
            {users.length} member
            {users.length === 1 ? "" : "s"}
          </div>

          {showInviteModal && (
            <InviteModal
              handleClose={() => {
                triggerRefetch()
                setShowInviteModal(false)
              }}
            />
          )}

          {showCreateVendorModal && (
            <CreateVendorModal
              handleClose={() => {
                triggerRefetch()
                setShowCreateVendorModal(false)
              }}
            />
          )}

          {showDeleteVendorModal && (
            <EditVendorModal
              handleClose={() => {
                triggerRefetch()
                setShowDeleteVendorModal(false)
              }}
              vendorList={vendorList}
            />
          )}

          {showCreateUserModal && (
            <CreateUserModal
              handleClose={() => {
                triggerRefetch()
                setShowCreateUserModal(false)
              }}
            />
          )}
        </BodyCard>
      </div>
    </div>
  )
}

export default Users
