import React, { useEffect, useState } from "react"
import { Controller, useFieldArray } from "react-hook-form"
import BodyCard from "../../../../components/organisms/body-card"
import { useProductForm } from "../form/product-form-context"
import Select from "../../../../components/molecules/select"
import { buildTitle } from "../../../../utils/string-helper"
import Medusa from "../../../../services/api"
import { intersectionWith, isEqual } from "lodash"
import Table from "../../../../components/molecules/table"
import Datetime from "react-datetime"
import "react-datetime/css/react-datetime.css"
import moment from "moment"
import "moment/locale/zh-hk"
import useImperativeDialog from "../../../../hooks/use-imperative-dialog"

const FreeSubscriber = ({ product }) => {
  const productRulesPolicyIdList = product.rules.map((r) =>
    parseInt(r.policy_id)
  )
  if (productRulesPolicyIdList.length === 0) {
    return null
  }

  const [freeUsers, setFreeUsers] = useState([])
  console.log(
    "🚀 ~ file: FreeSubscriber.tsx:16 ~ FreeSubscriber ~ freeUsers",
    freeUsers
  )
  const [freeDomains, setFreeDomains] = useState([])
  console.log(
    "🚀 ~ file: FreeSubscriber.tsx:18 ~ FreeSubscriber ~ freeDomains",
    freeDomains
  )

  const dialog = useImperativeDialog()

  const [newEmail, setNewEmail] = useState("")
  const [newEmailStart, setNewEmailStart] = useState(null)
  const [newEmailEnd, setNewEmailEnd] = useState(null)
  const [newDomain, setNewDomain] = useState("")
  const [newDomainStart, setNewDomainStart] = useState(null)
  const [newDomainEnd, setNewDomainEnd] = useState(null)

  const [editUserId, setEditUserId] = useState(null)
  const [editUserStart, setEditUserStart] = useState(null)
  const [editUserEnd, setEditUserEnd] = useState(null)

  const [editDomainId, setEditDomainId] = useState(null)
  const [editDomainStart, setEditDomainStart] = useState(null)
  const [editDomainEnd, setEditDomainEnd] = useState(null)

  const handleDeleteUser = async (u) => {
    const shouldDelete = await dialog({
      heading: "Delete Free User Subscription",
      text: "Are you sure you want to delete this free user subscription?",
    })

    if (shouldDelete) {
      const { data } = await Medusa.freeSub.deleteMemPol({
        email: u.email,
        policies: Array.from(productRulesPolicyIdList).map((id) => ({
          id,
        })),
      })
      fetchFreeSub()
    }
  }

  const handleDeleteDomain = async (d) => {
    const shouldDelete = await dialog({
      heading: "Delete Free Domain",
      text: "Are you sure you want to delete this free domain?",
    })

    if (shouldDelete) {
      const { data } = await Medusa.freeSub.deleteDomainPol({
        domain: d.domain_name,
        policies: Array.from(productRulesPolicyIdList).map((id) => ({
          id,
        })),
      })
      fetchFreeSub()
    }
  }

  const fetchFreeSub = async () => {
    const { data } = await Medusa.freeSub.getFreeSubscriberList()
    if (data?.code === 200) {
      const allUsers = data?.data?.users
      console.log(
        "🚀 ~ file: FreeSubscriber.tsx:38 ~ fetchFreeSub ~ allUsers:",
        allUsers
      )
      const usersForThisPolicyRules = allUsers
        .filter((u) => {
          const policyList = u.policies.map((p) => p.id)
          // return isEqual(policyList, productRulesPolicyIdList)
          return productRulesPolicyIdList.every((p) => policyList.includes(p))
        })
        .map((u) => {
          u.policies = u.policies.filter((p) =>
            productRulesPolicyIdList.includes(p.id)
          )
          return u
        })
      const allDomains = data?.data?.domains
      const domainsForThisPolicyRules = allDomains
        .filter((d) => {
          const policyList = d.policies.map((p) => p.id)
          // return isEqual(policyList, productRulesPolicyIdList)
          return productRulesPolicyIdList.every((p) => policyList.includes(p))
        })
        .map((d) => {
          d.policies = d.policies.filter((p) =>
            productRulesPolicyIdList.includes(p.id)
          )
          return d
        })
      setFreeDomains(domainsForThisPolicyRules)
      setFreeUsers(usersForThisPolicyRules)
    }
  }

  useEffect(() => {
    fetchFreeSub()
  }, [])

  return (
    <BodyCard title="Free Subscriber List">
      <div className="border-b py-1  grid grid-cols-4">
        <div>Email Address</div>
        <div>Start Date</div>
        <div>End Date</div>
        <div></div>
      </div>
      {freeUsers.map((u) => (
        <div className="border-b py-1 grid grid-cols-4" key={u.id}>
          <div>{u.email}</div>
          {editUserId === u.id ? (
            <>
              <Datetime
                key={"editUserStart" + editUserStart}
                // initialValue={u.policies[0].start_date}
                value={editUserStart}
                onChange={(v) => setEditUserStart(v)}
                inputProps={{
                  placeholder: "Start Date",
                  className: "border border-green-500",
                }}
                locale="zh-hk"
              />
              <Datetime
                key={"editUserEnd" + editUserEnd}
                // initialValue={u.policies[0].end_date}
                value={editUserEnd}
                onChange={(v) => setEditUserEnd(v)}
                inputProps={{
                  placeholder: "End Date",
                  className: "border border-green-500",
                }}
              />
            </>
          ) : (
            <>
              <div>
                {u.policies[0].start_date
                  ? moment(u.policies[0].start_date).format("YYYY/MM/DD HH:mm")
                  : "N/A"}
              </div>
              <div>
                {u.policies[0].end_date
                  ? moment(u.policies[0].end_date).format("YYYY/MM/DD HH:mm")
                  : "N/A"}
              </div>
            </>
          )}
          <div className="flex justify-end gap-x-2">
            {editUserId === u.id && (
              <>
                <button
                  className="bg-green-500 px-4 text-white"
                  onClick={async () => {
                    const { data } = await Medusa.freeSub.updateMemPol({
                      email: u.email,
                      policies: Array.from(
                        productRulesPolicyIdList
                      ).map((id) => ({ id })),
                      start_date: editUserStart
                        ? moment(editUserStart).toISOString()
                        : null,
                      end_date: editUserEnd
                        ? moment(editUserEnd).toISOString()
                        : null,
                    })
                    fetchFreeSub()
                    setEditUserId(null)
                    setEditUserStart(null)
                    setEditUserEnd(null)
                  }}
                >
                  complete
                </button>
                <button
                  className="bg-red-600 px-4 text-white"
                  onClick={() => setEditUserId(null)}
                >
                  cancel
                </button>
              </>
            )}
            <button
              className="bg-blue-500 px-4 text-white"
              onClick={() => {
                setEditUserId(u.id)
                setEditUserStart(
                  u.policies[0].start_date
                    ? moment(u.policies[0].start_date).format(
                        "YYYY/MM/DD HH:mm"
                      )
                    : null
                )
                setEditUserEnd(
                  u.policies[0].end_date
                    ? moment(u.policies[0].end_date).format("YYYY/MM/DD HH:mm")
                    : null
                )
              }}
            >
              edit
            </button>
            <button
              className="bg-red-600 px-4 text-white"
              onClick={async () => {
                await handleDeleteUser(u)
              }}
            >
              delete
            </button>
          </div>
        </div>
      ))}
      <div className="py-2 grid grid-cols-4">
        <input
          className="bg-white px-2 border "
          placeholder="new email"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
        />
        <div>
          <Datetime
            key={"newEmailStart" + newEmailStart}
            value={newEmailStart}
            onChange={(v) => setNewEmailStart(v)}
            inputProps={{ placeholder: "Start Date" }}
          />
        </div>
        <div>
          <Datetime
            key={"newEmailEnd" + newEmailEnd}
            value={newEmailEnd}
            onChange={(v) => setNewEmailEnd(v)}
            inputProps={{ placeholder: "End Date" }}
          />
        </div>
        <div className="flex justify-end">
          <button
            className="bg-green-600 px-4 text-white"
            onClick={async () => {
              const { data } = await Medusa.freeSub.createMemPol({
                email: newEmail,
                policies: Array.from(productRulesPolicyIdList).map((id) => ({
                  id,
                })),
                start_date: newEmailStart || null,
                end_date: newEmailEnd || null,
              })
              setNewEmail("")
              setNewEmailStart(null)
              setNewEmailEnd(null)
              fetchFreeSub()
            }}
          >
            add email
          </button>
        </div>
      </div>

      <div className="mt-8 border-b py-1  grid grid-cols-4">
        <div>Domain</div>
        <div>Start Date</div>
        <div>End Date</div>
        <div></div>
      </div>
      {freeDomains.map((d) => (
        <div className="border-b py-1 grid grid-cols-4" key={d.id}>
          <div>{d.domain_name}</div>
          {editDomainId === d.id ? (
            <>
              <Datetime
                key={"editDomainStart" + editDomainStart}
                // initialValue={d.policies[0].start_date}
                value={editDomainStart}
                onChange={(v) => setEditDomainStart(v)}
                inputProps={{
                  placeholder: "Start Date",
                  className: "border border-green-500",
                }}
              />
              <Datetime
                key={"editDomainEnd" + editDomainEnd}
                // initialValue={d.policies[0].end_date}
                value={editDomainEnd}
                onChange={(v) => setEditDomainEnd(v)}
                inputProps={{
                  placeholder: "End Date",
                  className: "border border-green-500",
                }}
              />
            </>
          ) : (
            <>
              <div>
                {d.policies[0].start_date
                  ? moment(d.policies[0].start_date).format("YYYY/MM/DD HH:mm")
                  : "N/A"}
              </div>
              <div>
                {d.policies[0].end_date
                  ? moment(d.policies[0].end_date).format("YYYY/MM/DD HH:mm")
                  : "N/A"}
              </div>
            </>
          )}
          <div className="flex justify-end gap-x-2">
            {editDomainId === d.id && (
              <>
                <button
                  className="bg-green-500 px-4 text-white"
                  onClick={async () => {
                    const { data } = await Medusa.freeSub.updateDomainPol({
                      domain: d.domain_name,
                      policies: Array.from(
                        productRulesPolicyIdList
                      ).map((id) => ({ id })),
                      start_date: editDomainStart
                        ? moment(editDomainStart).toISOString()
                        : null,
                      end_date: editDomainEnd
                        ? moment(editDomainEnd).toISOString()
                        : null,
                    })
                    fetchFreeSub()
                    setEditDomainId(null)
                    setEditDomainStart(null)
                    setEditDomainEnd(null)
                  }}
                >
                  complete
                </button>
                <button
                  className="bg-red-600 px-4 text-white"
                  onClick={() => setEditDomainId(null)}
                >
                  cancel
                </button>
              </>
            )}
            <button
              className="bg-blue-500 px-4 text-white"
              onClick={() => {
                setEditDomainId(d.id)
                setEditDomainStart(
                  d.policies[0].start_date
                    ? moment(d.policies[0].start_date).format(
                        "YYYY/MM/DD HH:mm"
                      )
                    : null
                )
                setEditDomainEnd(
                  d.policies[0].end_date
                    ? moment(d.policies[0].end_date).format("YYYY/MM/DD HH:mm")
                    : null
                )
              }}
            >
              edit
            </button>
            <button
              className="bg-red-600 px-4 text-white"
              onClick={async () => {
                await handleDeleteDomain(d)
              }}
            >
              delete
            </button>
          </div>
        </div>
      ))}
      <div className="py-2 grid grid-cols-4">
        <input
          value={newDomain}
          className="bg-white px-2 border"
          placeholder="new domain"
          onChange={(e) => setNewDomain(e.target.value)}
        />
        <div>
          <Datetime
            key={"newDomainStart" + newDomainStart}
            value={newDomainStart}
            onChange={(v) => setNewDomainStart(v)}
            inputProps={{ placeholder: "Start Date" }}
          />
        </div>
        <div>
          <Datetime
            key={"newDomainEnd" + newDomainEnd}
            value={newDomainEnd}
            onChange={(v) => setNewDomainEnd(v)}
            inputProps={{ placeholder: "End Date" }}
          />
        </div>
        <div className="flex justify-end">
          <button
            className="bg-green-600 px-4 text-white"
            onClick={async () => {
              const { data } = await Medusa.freeSub.createDomainPol({
                domain: newDomain,
                policies: Array.from(productRulesPolicyIdList).map((id) => ({
                  id,
                })),
                start_date: newDomainStart || null,
                end_date: newDomainEnd || null,
              })
              setNewDomain("")
              setNewDomainStart(null)
              setNewDomainEnd(null)
              // alert(JSON.stringify(data,null,2))
              fetchFreeSub()
            }}
          >
            add domain
          </button>
        </div>
      </div>
    </BodyCard>
  )
}

export default FreeSubscriber
