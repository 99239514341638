import { useAdminStore } from "medusa-react"
import React, { useEffect, useState } from "react"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import Checkbox from "../../../../components/atoms/checkbox"
import Button from "../../../../components/fundamentals/button"
import PlusIcon from "../../../../components/fundamentals/icons/plus-icon"
import TrashIcon from "../../../../components/fundamentals/icons/trash-icon"
import IconTooltip from "../../../../components/molecules/icon-tooltip"
import Input from "../../../../components/molecules/input"
import Modal from "../../../../components/molecules/modal"
import CurrencyInput from "../../../../components/organisms/currency-input"
import { convertEmptyStringToNull } from "../../../../utils/convert-empty-string-to-null"
import { countries as countryData } from "../../../../utils/countries"
import { focusByName } from "../../../../utils/focus-by-name"
import usePricesFieldArray from "../../product-form/form/usePricesFieldArray"
import { isJson } from "../../../../utils/form-helpers"
import useNotification from "../../../../hooks/use-notification"
import { ProductOption, DurationOption } from "../../../../types/shared"
import Select from "../../../../components/molecules/select"
import Textarea from "../../../../components/molecules/textarea"

const defaultVariant = {
  prices: [] as any,
  origin_country: "",
  options: [] as any,
}

const VariantEditor = ({
  variant = defaultVariant,
  onSubmit,
  onCancel,
  title,
  optionsMap,
  isSubscription,
  survey_id,
  defaultDescription,
  defaultDescriptionRows,
}) => {
  const countryOptions = countryData.map((c) => ({
    label: c.name,
    value: c.alpha2.toLowerCase(),
  }))

  const notification = useNotification()

  const { store } = useAdminStore()
  const [selectedCountry, setSelectedCountry] = useState(() => {
    const defaultCountry = variant.origin_country
      ? countryOptions.find((cd) => cd.label === variant.origin_country)
      : null
    return defaultCountry || null
  })

  const [questionGroup, setQuestionGroup] = useState(null)

  const [recursivePayment, setRecursivePayment] = useState(
    variant.metadata?.recursive_payment || false
  )
  const [iosProductId, setIOSProductId] = useState(
    variant.metadata?.ios_product_id || ""
  )
  const [googleProductId, setGoogleProductId] = useState(
    variant.metadata?.google_product_id || ""
  )

  const [descriptionRows, setDescriptionRows] = useState(
    variant.metadata?.description_rows || defaultDescriptionRows || [""]
  )

  const appendDescriptionRows = () => {
    const newDescriptions = [...descriptionRows, ""]
    setDescriptionRows(newDescriptions)
  }

  const deleteDescriptionRows = (index) => {
    descriptionRows.splice(index, 1)
    const newDescriptions = [...descriptionRows]
    setDescriptionRows(newDescriptions)
  }

  const handleDescriptionRowChange = (value, index) => {
    descriptionRows[index] = value
    const newDescriptions = [...descriptionRows]
    setDescriptionRows(newDescriptions)
  }

  const [description, setDescription] = useState(
    variant.metadata?.description || defaultDescription || ""
  )

  const [recommended, setRecommended] = useState(
    variant.metadata?.recommended || false
  )

  const { control, register, reset, watch, handleSubmit } = useForm({
    defaultValues: variant,
  })
  const {
    fields: prices,
    appendPrice,
    deletePrice,
    availableCurrencies,
  } = usePricesFieldArray(
    store?.currencies.map((c) => c.code) || [],
    {
      control,
      name: "prices",
      keyName: "indexId",
    },
    {
      defaultAmount: 1000,
      defaultCurrencyCode:
        store?.default_currency.code || store?.currencies[0].code || "usd",
      defaultCaption: "",
    }
  )

  const { fields } = useFieldArray({
    control,
    name: "options",
    keyName: "indexId",
  })

  const durationOptions = [
    {
      label: "1 Day",
      value: DurationOption.DAY,
    },
    {
      label: "1 Month",
      value: DurationOption.MONTH,
    },
    {
      label: "3 Month",
      value: DurationOption.THREE_MONTH,
    },
    {
      label: "6 Month",
      value: DurationOption.SIX_MONTH,
    },
    {
      label: "1 Year",
      value: DurationOption.YEAR,
    },
  ]

  let durationOption

  Object.keys(optionsMap).forEach((key) => {
    if (optionsMap[key].title === ProductOption.DURATION) {
      durationOption = optionsMap[key]
    }
  })

  const [duration, setDuration] = useState(() => {
    let d = durationOptions[0]
    if (durationOption) {
      variant.options?.forEach((o) => {
        if (o.id === durationOption.id) {
          if (o.value === DurationOption.DAY) {
            d = durationOptions[0]
          } else if (o.value === DurationOption.MONTH) {
            d = durationOptions[1]
          } else if (o.value === DurationOption.THREE_MONTH) {
            d = durationOptions[2]
          } else if (o.value === DurationOption.SIX_MONTH) {
            d = durationOptions[3]
          } else if (o.value === DurationOption.YEAR) {
            d = durationOptions[4]
          }
        }
      })
    }

    return d
  })

  const handleDuration = (value) => {
    setDuration(value)
  }

  useEffect(() => {
    reset({
      ...variant,
      options: Object.values(optionsMap),
      prices: variant?.prices.map((p) => ({
        price: { ...p },
      })),
    })
  }, [variant, store])

  const handleSave = (data) => {
    if (!data.prices) {
      focusByName("add-price")
      return
    }

    data.prices = data.prices.map(({ price: { currency_code, amount } }) => ({
      currency_code,
      amount: Math.round(amount),
    }))

    if (isSubscription) {
      if (data.options && data.options.length > 0) {
        data.options.forEach((o, index) => {
          // console.log(o, durationOption.option_id, o.id === durationOption.option_id)
          if (o.option_id === durationOption.option_id) {
            data.options[index].value = duration.value
          }
        })
      }
      data.metadata = {
        ...data?.metadata,
        ios_product_id: iosProductId,
        google_product_id: googleProductId,
        recursive_payment: recursivePayment,
        description_rows: descriptionRows,
        description,
        recommended,
      }
    }

    if (!data.title) {
      data.title = data.options.map((o) => o.value).join(" / ")
    }

    data.origin_country = selectedCountry?.label
    data.inventory_quantity = parseInt(data.inventory_quantity)
    data.weight = data?.weight ? parseInt(data.weight, 10) : undefined
    data.height = data?.height ? parseInt(data.height, 10) : undefined
    data.width = data?.width ? parseInt(data.width, 10) : undefined
    data.length = data?.length ? parseInt(data.length, 10) : undefined

    console.log("save variant data", data)

    // exclude surveys when updating variant, create new survey when create 1st variant
    // create 2nd & after variant with existing survey
    if (survey_id) {
      data.surveys = [
        {
          id: survey_id,
        },
      ]
    }

    // console.log("data", data)

    const cleaned = convertEmptyStringToNull(data)
    onSubmit(cleaned)
  }

  const checkDefaultDuration = (field) => {
    if (field.title === ProductOption.DURATION && field.value.length <= 0) {
      return DurationOption.MONTH
    }
    return field.value
  }

  watch(["manage_inventory", "allow_backorder"])

  const variantTitle = variant?.options
    .map((opt) => opt?.value || "")
    .join(" / ")

  const checkOptionHide = (title) => {
    if (isSubscription && title === ProductOption.DURATION) {
      return false
    } else if (!isSubscription && title !== ProductOption.DURATION) {
      return false
    }
    return true
  }

  return (
    <Modal handleClose={onCancel}>
      <Modal.Body>
        <Modal.Header handleClose={onCancel}>
          <h2 className="inter-xlarge-semibold">
            {title}{" "}
            {variantTitle && (
              <span className="text-grey-50 inter-xlarge-regular">
                ({variantTitle})
              </span>
            )}
          </h2>
        </Modal.Header>
        <Modal.Content>
          {!isSubscription && (
            <div className="mb-8">
              <label
                tabIndex={0}
                className="inter-base-semibold mb-4 flex items-center gap-xsmall"
              >
                {"General"}
              </label>

              <div className="grid grid-cols-1 gap-y-small">
                {fields.map((field, index) => (
                  <div
                    key={field.indexId}
                    style={{
                      display: checkOptionHide(field.title) ? "none" : "block",
                    }}
                  >
                    <Input
                      ref={register({ required: true })}
                      name={`options.${index}.value`}
                      required={true}
                      label={field.title}
                      defaultValue={checkDefaultDuration(field)}
                    />
                    <input
                      ref={register()}
                      type="hidden"
                      name={`options.${index}.option_id`}
                      defaultValue={field.option_id}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="mb-8">
            <label
              tabIndex={0}
              className="inter-base-semibold mb-4 flex items-center"
            >
              {"Prices"}
              <span className="text-rose-50 mr-xsmall">*</span>
              <IconTooltip content={"Variant prices"} />
            </label>

            <div className="grid grid-cols-1 gap-y-xsmall">
              {prices.map((field, index) => (
                <div className="flex items-center" key={field.indexId}>
                  <div className="w-full">
                    <Controller
                      control={control}
                      key={field.indexId}
                      name={`prices.${index}.price`}
                      ref={register()}
                      defaultValue={field.price}
                      render={({ onChange, value }) => {
                        let codes = availableCurrencies
                        if (value?.currency_code) {
                          codes = [value?.currency_code, ...availableCurrencies]
                        }
                        codes.sort()
                        return (
                          <CurrencyInput
                            currencyCodes={codes}
                            currentCurrency={value?.currency_code}
                            size="medium"
                            readOnly={index === 0}
                            onChange={(code) =>
                              onChange({ ...value, currency_code: code })
                            }
                          >
                            <CurrencyInput.AmountInput
                              label="Amount"
                              onChange={(amount) =>
                                onChange({ ...value, amount })
                              }
                              amount={value?.amount}
                            />
                            {/* <div className="flex gap-x-2xsmall"> */}

                            {/* <Input
                                label="Price caption"
                                onChange={(caption) =>
                                  onChange({ ...value, caption })
                                }
                                placeholder="price caption"
                              /> */}
                            {/* </div> */}
                          </CurrencyInput>
                        )
                      }}
                    />
                  </div>

                  <Button
                    variant="ghost"
                    size="small"
                    className="ml-8 w-8 h-8 mr-2.5 text-grey-40 hover:text-grey-80 transition-colors"
                    onClick={deletePrice(index)}
                  >
                    <TrashIcon />
                  </Button>
                </div>
              ))}
            </div>
            <Button
              className="mt-4"
              onClick={appendPrice}
              size="small"
              variant="ghost"
              name="add-price"
              disabled={availableCurrencies?.length === 0}
            >
              <PlusIcon size={20} /> Add a price
            </Button>
          </div>
          <div className="mb-8">
            <label className="inter-base-semibold flex items-center gap-xsmall mb-4">
              {"Stock & Inventory"}
              <IconTooltip content={"Stock and inventory information"} />
            </label>
            <Input
              label="Max Number of Purchase"
              name="max_num_of_purchase"
              placeholder="1"
              type="number"
              ref={register}
            />
            <div className="w-full mt-4 grid medium:grid-cols-2 grid-cols-1 gap-y-base gap-x-xsmall">
              <Input label="SKU" name="sku" placeholder="SKU" ref={register} />
              {/* <Input label="EAN" name="ean" placeholder="EAN" ref={register} /> */}
              <Input
                label="Inventory quantity"
                name="inventory_quantity"
                placeholder="100"
                type="number"
                ref={register}
              />

              {/* <Input
                label="UPC Barcode"
                name="barcode"
                placeholder="Barcode"
                ref={register}
              /> */}
            </div>

            <div className="flex items-center mt-6 gap-x-large">
              <div className="flex item-center gap-x-1.5 opacity-50">
                <Checkbox
                  name="manage_inventory"
                  label="Manage Inventory"
                  ref={register}
                  defaultChecked={!isSubscription}
                  disabled={true}
                />
                <IconTooltip
                  content={
                    "When checked Medusa will regulate the inventory when orders and returns are made."
                  }
                />
              </div>
              <div className="flex item-center gap-x-1.5 opacity-50">
                <Checkbox
                  name="allow_backorder"
                  label="Allow backorders"
                  ref={register}
                  defaultChecked={isSubscription}
                  disabled={true}
                />
                <IconTooltip
                  content={
                    "When checked the product will be available for purchase despite the product being sold out."
                  }
                />
              </div>
            </div>
          </div>

          {!isSubscription && (
            <div>
              <div className="mb-8">
                <label className="mb-4 inter-base-semibold flex items-center gap-xsmall">
                  {"Terms & Conditions"}
                </label>
                <div className="mb-4">
                  <Checkbox
                    name="metadata.is_override_product_terms_conditions"
                    label="Override Product Terms & Conditions"
                    ref={register}
                    // defaultChecked={isSubscription}
                    // disabled={true}
                  />
                </div>
                <div className="flex item-center gap-x-1.5 mb-xlarge">
                  <Textarea
                    ref={register}
                    name="metadata.variant_terms_conditions"
                    // id="description"
                    label="Variant Terms & Conditions"
                    placeholder="Terms & conditions of the variant..."
                    // value={variantTermsConditions}
                    // onChange={(e) => setVariantTermsConditions(e.target.value)}
                    className="row-span-full"
                    rows={8}
                  />
                </div>
              </div>
            </div>
          )}

          {isSubscription && (
            <div>
              <div className="mb-8">
                <label className="mb-4 inter-base-semibold flex items-center gap-xsmall">
                  {"Subscription"}
                </label>
                {fields.map((field, index) => (
                  <div
                    className="flex gap-2 mb-base"
                    style={{
                      display: checkOptionHide(field.title) ? "none" : "flex",
                    }}
                    key={field.indexId}
                  >
                    <Select
                      required={true}
                      label="Choose duration"
                      value={duration}
                      onChange={handleDuration}
                      options={durationOptions}
                    />
                    {/* <Input
                      label="Duration caption"
                      name={`options.${index}.metadata`}
                      placeholder="duration caption"
                      ref={register}
                    /> */}
                    <input
                      ref={register()}
                      type="hidden"
                      name={`options.${index}.value`}
                      defaultValue={field.value}
                    />
                    <input
                      ref={register()}
                      type="hidden"
                      name={`options.${index}.option_id`}
                      defaultValue={field.option_id}
                    />
                  </div>
                ))}
                <div className="flex item-center gap-x-1.5 mb-xlarge">
                  <Checkbox
                    name="Recursive Payment"
                    label="Recursive Payment"
                    checked={recursivePayment}
                    onChange={(e) => {
                      setRecursivePayment(e.target.checked)
                    }}
                  />
                  <IconTooltip content={"Recursive Payment"} />
                </div>
              </div>
              {descriptionRows.map((option, index) => (
                <div key={index} className="w-full flex">
                  <div className="w-full mt-4 grid medium:grid-cols-2 grid-cols-1 gap-y-base gap-x-xsmall">
                    <Input
                      name={`descriptions${index}`}
                      label={`Subscription Plan Description Row ${index + 1}`}
                      placeholder="Placeholder"
                      value={option}
                      onChange={(event) => {
                        handleDescriptionRowChange(event.target.value, index)
                      }}
                    />
                    <button>
                      <TrashIcon
                        size={20}
                        onClick={() => deleteDescriptionRows(index)}
                        className="text-grey-40"
                      />
                    </button>
                  </div>
                </div>
              ))}

              <div className="mt-large mb-small">
                <Button
                  onClick={appendDescriptionRows}
                  type="button"
                  variant="ghost"
                  size="small"
                >
                  <PlusIcon size={20} />
                  Add Row
                </Button>
              </div>

              <div className="flex item-center gap-x-1.5 mb-xlarge">
                <Textarea
                  name="description"
                  id="description"
                  label="Description"
                  placeholder="Short description of the variant..."
                  className="row-span-full"
                  rows={8}
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value)
                  }}
                />
              </div>

              <div className="flex item-center gap-x-1.5 mb-xlarge">
                <Checkbox
                  name="recommended"
                  label="Recommended Plan"
                  checked={recommended}
                  onChange={(e) => {
                    setRecommended(e.target.checked)
                  }}
                />
                <IconTooltip content={"Display Recommended Label"} />
              </div>
              <div className="mb-8">
                <label className="inter-base-semibold flex items-center gap-xsmall mb-4">
                  {"App Product ID"}
                  <IconTooltip content={"App Product ID information"} />
                </label>
                <div className="w-full mt-4 grid medium:grid-cols-2 grid-cols-1 gap-y-base gap-x-xsmall">
                  <Input
                    label="iOS Product ID"
                    name="iOS Product ID"
                    placeholder="IOS Product ID"
                    value={iosProductId}
                    onChange={(e) => {
                      setIOSProductId(e.target.value)
                    }}
                    // ref={register({
                    //   required: "iOS Product ID is required",
                    //   minLength: 1,
                    // })}
                  />
                  <Input
                    label="Google Product ID"
                    name="Google Product ID"
                    placeholder="Google Product ID"
                    value={googleProductId}
                    onChange={(e) => {
                      setGoogleProductId(e.target.value)
                    }}
                    // ref={register({
                    //   required: "Google Product ID is required",
                    //   minLength: 1,
                    // })}
                  />
                </div>
              </div>
            </div>
          )}
          {/* <div className="mb-8">
            <label className="inter-base-semibold flex items-center gap-xsmall">
              Dimensions <IconTooltip content={"Variant dimensions"} />
            </label>
            <div className="w-full mt-4 grid medium:grid-cols-2 grid-cols-1 gap-y-base gap-x-xsmall">
              <Input
                label="Height"
                placeholder="Product Height"
                name="height"
                ref={register}
              />
              <Input
                label="Width"
                placeholder="Product Width"
                name="width"
                ref={register}
              />
              <Input
                label="Length"
                name="length"
                placeholder="Product Length"
                ref={register}
              />
              <Input
                label="Weight"
                name="weight"
                placeholder="Product Weight"
                ref={register}
              />
            </div>
          </div>
          <div className="mb-8">
            <label className="inter-base-semibold flex items-center gap-xsmall">
              Customs <IconTooltip content={"Variant customs information"} />
            </label>
            <div className="w-full grid mt-4 medium:grid-cols-2 grid-cols-1 gap-y-base gap-x-xsmall">
              <Input
                label="MID Code"
                placeholder="MID Code"
                name="mid_code"
                ref={register}
              />
              <Input
                label="HS Code"
                placeholder="HS Code"
                name="hs_code"
                ref={register}
              />
              <Select
                enableSearch
                label={"Country of origin"}
                options={countryOptions}
                value={selectedCountry}
                onChange={setSelectedCountry}
              />
              <Input
                label="Material"
                name="material"
                placeholder="Material"
                ref={register}
              />
            </div>
          </div> */}
        </Modal.Content>
        <Modal.Footer>
          <div className="flex w-full justify-end gap-x-base">
            <Button
              className="w-[127px]"
              onClick={onCancel}
              size="small"
              variant="ghost"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(handleSave)}
              type="submit"
              className="w-[127px]"
              size="small"
              variant="primary"
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}

export default VariantEditor
