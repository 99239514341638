import { RouteComponentProps, Router } from "@reach/router"
import React, { useMemo, useState } from "react"
import ExportIcon from "../../components/fundamentals/icons/export-icon"
import BodyCard from "../../components/organisms/body-card"
import TableViewHeader from "../../components/organisms/custom-table-header"
import Details from "./details"
import CustomerSubscriptionTable from "../../components/templates/customer-subscription-table"
import Button from "../../components/fundamentals/button"
import Medusa from "../../services/api"
import useNotification from "../../hooks/use-notification"
import { getErrorMessage } from "../../utils/error-messages"

const SubscriptionIndex: React.FC<RouteComponentProps> = () => {
  const [exportFilter, setExportFilter] = useState<Record<string, unknown>>({})
  const notification = useNotification()
  const actions = (
    <Button
      variant="secondary"
      size="small"
      onClick={async () => {
        try {
          await Medusa.batchJobs.postBatchJobs({
            type: "subscription-export",
            context: {
              selector: {
                login_user_id: exportFilter.login_user_id,
                login_source: exportFilter.login_source,
                q: exportFilter.q,
                status: exportFilter.status,
                duration: exportFilter.duration,
                start_date: exportFilter.start_date,
                end_date: exportFilter.end_date,
                payment_provider: exportFilter.payment_provider,
              },
              options: {
                take: Infinity,
                skip: 0,
                relations: ["customer"],
                order: {
                  start_date: "desc",
                },
              },
            },
            dry_run: false,
          })
          notification("Success", "Successfully initiated export", "success")
        } catch (error) {
          notification("Error", getErrorMessage(error), "error")
        }
      }}
    >
      <ExportIcon size={20} />
      Export
    </Button>
  )

  return (
    <>
      <div className="flex flex-col grow h-full">
        <div className="w-full flex flex-col grow">
          <BodyCard
            customHeader={<TableViewHeader views={["subscriptions"]} />}
            customActionable={actions}
          >
            <CustomerSubscriptionTable setExportFilter={setExportFilter} />
          </BodyCard>
        </div>
      </div>
    </>
  )
}

const Subscriptions = () => {
  return (
    <Router>
      <SubscriptionIndex path="/" />
      <Details path=":id" />
    </Router>
  )
}

export default Subscriptions
