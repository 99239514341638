import clsx from "clsx"
import React, { useMemo, useEffect, useState } from "react"
import CheckIcon from "../../components/fundamentals/icons/check-icon"
import PlusIcon from "../../components/fundamentals/icons/plus-icon"
import FilterDropdownContainer from "../../components/molecules/filter-dropdown/container"
import SaveFilterItem from "../../components/molecules/filter-dropdown/save-field"
import TabFilter from "../../components/molecules/filter-tab"
import TextInput from "../../components/molecules/text-input"
import FilterDropdownItem from "../../components/molecules/filter-dropdown/item"

const dateFilters = [
  "is in the last",
  "is older than",
  "is between",
  "is after",
  "is before",
  "is equal to",
]

const startDateFilter = [
  "is between",
  "is after",
  "is before",
  "is equal to",
]

const statusFilters = [
  { label: "Active (Auto-renew)", value: "active" },
  { label: "Expired", value: "inactive" },
  { label: "Active (No-renew)", value: "stop" },
]

const paymentProviderFilters = [
  { label: "stripe", value: "stripe" },
  { label: "iosiap", value: "iosiap" },
  { label: "gpa", value: "gpa" },
]

const durationFilter = [
  '1Y',
  '6M',
  '3M',
  '1M'
]

const SubscriptionFilter = ({
  filters,
  submitFilters,
  clearFilters,
  tabs,
  onTabClick,
  activeTab,
  onRemoveTab,
  onSaveTab,
}) => {
  const [tempState, setTempState] = useState(filters)
  const [name, setName] = useState("")

  const handleRemoveTab = (val) => {
    if (onRemoveTab) {
      onRemoveTab(val)
    }
  }

  const handleSaveTab = () => {
    if (onSaveTab) {
      onSaveTab(name, tempState)
    }
  }

  const handleTabClick = (tabName: string) => {
    if (onTabClick) {
      onTabClick(tabName)
    }
  }

  useEffect(() => {
    setTempState(filters)
  }, [filters])

  const onSubmit = () => {
    submitFilters(tempState)
  }

  const onClear = () => {
    clearFilters()
  }

  const numberOfFilters = useMemo(
    () =>
      Object.entries(filters).reduce((acc, [, value]) => {
        if (value?.open) {
          acc = acc + 1
        }
        return acc
      }, 0),
    [filters]
  )

  const setSingleFilter = (filterKey, filterVal) => {
    // setTempState({
    //   ...tempState,
    //   [filterKey]: filterVal,
    // })
    setTempState((prevState) => ({
      ...prevState,
      [filterKey]: filterVal,
    }))
  }

  return (
    <div className="flex space-x-1">
      {/* <FilterDropdownContainer
        submitFilters={onSubmit}
        clearFilters={onClear}
        triggerElement={
          <button
            className={clsx(
              "flex rounded-rounded items-center space-x-1 focus-visible:outline-none focus-visible:shadow-input focus-visible:border-violet-60"
            )}
          >
            <div className="flex rounded-rounded items-center bg-grey-5 border border-grey-20 inter-small-semibold px-2 h-6">
              Filters
              <div className="text-grey-40 ml-1 flex items-center rounded">
                <span className="text-violet-60 inter-small-semibold">
                  {numberOfFilters ? numberOfFilters : "0"}
                </span>
              </div>
            </div>
            <div className="flex items-center rounded-rounded bg-grey-5 border border-grey-20 inter-small-semibold p-1">
              <PlusIcon size={14} />
            </div>
          </button>
        }
      >
        <div className="flex flex-col w-full pb-2">
          <div
            className="flex w-full items-center px-3 mb-1 py-1.5 hover:bg-grey-5 rounded cursor-pointer"
            onClick={() => {
              setSingleFilter("login_user_id", {
                open: !tempState.login_user_id.open,
                filter: tempState.login_user_id.filter,
              })
            }}
          >
            <div
              className={`w-5 h-5 flex justify-center border-grey-30 border text-grey-0 rounded-base ${tempState.login_user_id.open && "bg-violet-60"
                }`}
            >
              <span className="self-center">
                {tempState.login_user_id.open && <CheckIcon size={16} />}
              </span>
              <input
                type="checkbox"
                className="hidden"
                id="Tags"
                name="Tags"
                value="Tags"
                checked={tempState.login_user_id.open}
              />
            </div>
            <span
              className={clsx("text-grey-90 ml-2", {
                "inter-small-semibold": tempState.login_user_id.open,
                "inter-small-regular": !tempState.login_user_id.open,
              })}
            >
              Login User ID
            </span>
          </div>

          {tempState.login_user_id.open && (
            <div data-tip="login_user_id" className="pl-6">
              <TextInput
                className="pt-0 pb-1"
                placeholder="login_user_id"
                value={tempState.login_user_id.filter}
                onChange={(value) => {
                  setSingleFilter("login_user_id", {
                    open: tempState.login_user_id.open,
                    filter: value,
                  })
                }}
              />
            </div>
          )}
        </div>

        <div className="flex flex-col w-full pb-2">
          <div
            className="flex w-full items-center px-3 mb-1 py-1.5 hover:bg-grey-5 rounded cursor-pointer"
            onClick={() => {
              setSingleFilter("login_source", {
                open: !tempState.login_source.open,
                filter: tempState.login_source.filter,
              })
            }}
          >
            <div
              className={`w-5 h-5 flex justify-center border-grey-30 border text-grey-0 rounded-base ${tempState.login_source.open && "bg-violet-60"
                }`}
            >
              <span className="self-center">
                {tempState.login_source.open && <CheckIcon size={16} />}
              </span>
              <input
                type="checkbox"
                className="hidden"
                id="Tags"
                name="Tags"
                value="Tags"
                checked={tempState.login_source.open}
              />
            </div>
            <span
              className={clsx("text-grey-90 ml-2", {
                "inter-small-semibold": tempState.login_source.open,
                "inter-small-regular": !tempState.login_source.open,
              })}
            >
              Login Source
            </span>
          </div>

          {tempState.login_source.open && (
            <div data-tip="login_source" className="pl-6">
              <TextInput
                className="pt-0 pb-1"
                placeholder="login_source"
                value={tempState.login_source.filter}
                onChange={(value) => {
                  setSingleFilter("login_source", {
                    open: tempState.login_source.open,
                    filter: value,
                  })
                }}
              />
            </div>
          )}
        </div>
        <SaveFilterItem
          saveFilter={handleSaveTab}
          name={name}
          setName={setName}
        />
      </FilterDropdownContainer> */}

      <FilterDropdownContainer
        submitFilters={onSubmit}
        clearFilters={onClear}
        triggerElement={
          <button
            className={clsx(
              "flex rounded-rounded items-center space-x-1 focus-visible:outline-none focus-visible:shadow-input focus-visible:border-violet-60",
              // {
              //   ["bg-violet-5 border-violet-60 text-violet-60 "]:
              //     !!tempState?.date?.filter && tempState?.date?.open,
              // }
            )}
          >
            <div className="flex rounded-rounded items-center bg-grey-5 border border-grey-20 inter-small-semibold px-2 h-6">
              Filters
              <div className="text-grey-40 ml-1 flex items-center rounded">
                <span className="text-violet-60 inter-small-semibold">
                  {numberOfFilters ? numberOfFilters : "0"}
                </span>
              </div>
            </div>
            <div className="flex items-center rounded-rounded bg-grey-5 border border-grey-20 inter-small-semibold p-1">
              <PlusIcon size={14} />
            </div>
          </button>
        }
      >
        <FilterDropdownItem
          filterTitle="Start Date"
          options={startDateFilter}
          filters={tempState.start_date.filter}
          open={tempState.start_date.open}
          setFilter={(val) => {
            setSingleFilter("start_date", val)
            console.log("🚀 ~ file: filter-dropdown.tsx:270 ~ val:", val)
          }}
        />
        <FilterDropdownItem
          filterTitle="End Date"
          options={startDateFilter}
          filters={tempState.end_date.filter}
          open={tempState.end_date.open}
          setFilter={(val) => {
            setSingleFilter("end_date", val)
            console.log("🚀 ~ file: filter-dropdown.tsx:270 ~ val:", val)
          }}
        />
        <FilterDropdownItem
          filterTitle="Status"
          options={statusFilters}
          filters={tempState.status.filter}
          open={tempState.status.open}
          setFilter={(val) => setSingleFilter("status", val)}
        />
        <FilterDropdownItem
          filterTitle="Payment Provider"
          options={paymentProviderFilters}
          filters={tempState.payment_provider.filter}
          open={tempState.payment_provider.open}
          setFilter={(val) => setSingleFilter("payment_provider", val)}
        />
        <FilterDropdownItem
          filterTitle="Duration"
          options={durationFilter}
          filters={tempState.duration.filter}
          open={tempState.duration.open}
          setFilter={(val) => setSingleFilter("duration", val)}
        />
      </FilterDropdownContainer>

      {tabs &&
        tabs.map((t) => (
          <TabFilter
            key={t.value}
            onClick={() => handleTabClick(t.value)}
            label={t.label}
            isActive={activeTab === t.value}
            removable={!!t.removable}
            onRemove={() => handleRemoveTab(t.value)}
          />
        ))}
    </div>
  )
}

export default SubscriptionFilter
