export type Option = {
  value: string
  label: string
}

export enum ProductStatus {
  DRAFT = "draft",
  PROPOSED = "proposed",
  PUBLISHED = "published",
  REJECTED = "rejected",
}

export type DateFilter = null | {
  gt?: string
  lt?: string
}

export enum TaxRateType {
  REGION = "region",
  RATE = "rate",
}

export type PaginationProps = {
  limit: number
  offset: number
}

export type Idable = { id: string; [x: string]: any }

export enum ProductType {
  ARTICLE_SUBSCRIPTION = "article_subscription",
  PRODUCT = "product",
}

export enum ProductOption {
  SIZE = "Size",
  COLOR = "Color",
  DURATION = "Duration",
}

export enum DurationOption {
  DAY = "1D",
  MONTH = "1M",
  THREE_MONTH = "3M",
  SIX_MONTH = "6M",
  YEAR = "1Y",
}

export enum DurationUnitOption {
  DAY = "D",
  MONTH = "M",
  YEAR = "Y",
}
