import moment from "moment"
import React, { useMemo } from "react"
import ReactCountryFlag from "react-country-flag"
import { getColor } from "../../../utils/color"
import { isoAlpha2Countries } from "../../../utils/countries"
import { formatAmountWithSymbol } from "../../../utils/prices"
import Tooltip from "../../atoms/tooltip"
import StatusDot from "../../fundamentals/status-indicator"
import CustomerAvatarItem from "../../molecules/customer-avatar-item"
import Table from "../../molecules/table"

const useOrderTableColums = () => {
  const decideStatus = (status) => {
    switch (status) {
      case "captured":
        return <StatusDot variant="success" title={"Paid"} />
      case "awaiting":
        return <StatusDot variant="default" title={"Awaiting"} />
      case "requires_action":
        return <StatusDot variant="danger" title={"Requires action"} />
      case "canceled":
        return <StatusDot variant="warning" title={"Canceled"} />
      default:
        return <StatusDot variant="primary" title={"N/A"} />
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: <Table.HeadCell className="pl-2">Order</Table.HeadCell>,
        accessor: "display_id",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell
            key={index}
            className="text-grey-90 group-hover:text-violet-60 min-w-[100px] pl-2"
          >{`#${value}`}</Table.Cell>
        ),
      },
      {
        Header: "Date added",
        accessor: "created_at",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index}>
            <Tooltip content={moment(value).format("YYYY-MM-DD HH:mm")}>
              {moment(value).format("YYYY-MM-DD HH:mm")}
            </Tooltip>
          </Table.Cell>
        ),
      },
      {
        Header: "Customer",
        accessor: "metadata.name",
        Cell: ({ row, cell: { value }, index }) => (
          <Table.Cell key={index} className="max-w-[200px] truncate">
            {/* <CustomerAvatarItem
              customer={{
                first_name: value.first_name,
                last_name: value.last_name,
              }}
              color={getColor(row.index)}
            /> */}
            {value}
          </Table.Cell>
        ),
      },
      {
        Header: "Product",
        accessor: "items.0.title",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index} className="max-w-[200px] truncate pr-4">
            {value}
          </Table.Cell>
        ),
      },
      {
        Header: "Varaint",
        accessor: "items.0.variant.title",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index} className="max-w-[200px] truncate">
            {value}
          </Table.Cell>
        ),
      },
      {
        Header: "Platform",
        accessor: "metadata.platform",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index} className="max-w-[200px] truncate">
            {value || "N/A"}
          </Table.Cell>
        ),
      },
      // {
      //   Header: "Subscription Status",
      //   accessor: "metadata.subscription_end_date",
      //   Cell: ({ cell: { value }, index }) => (
      //     <Table.Cell key={index} className="max-w-[200px] truncate">
      //       <Tooltip content={moment(value).format("YYYY-MM-DD HH:mm")}>
      //         {value
      //           ? moment(value).isBefore(moment())
      //             ? "inactive"
      //             : "active"
      //           : ""}
      //       </Tooltip>
      //     </Table.Cell>
      //   ),
      // },
      // {
      //   Header: "Is Sandbox",
      //   accessor: "metadata.sandbox_payment",
      //   Cell: ({ cell: { value }, index }) => (
      //     <Table.Cell key={index}>
      //       {value === true ? "Yes" : value === false ? "No" : "N/A"}
      //     </Table.Cell>
      //   ),
      // },
      // {
      //   Header: "Is Testing",
      //   accessor: "metadata.is_testing",
      //   Cell: ({ cell: { value }, index }) => (
      //     <Table.Cell key={index}>{value ? "Yes" : "No"}</Table.Cell>
      //   ),
      // },
      // {
      //   Header: "Fulfillment",
      //   accessor: "fulfillment_status",
      //   Cell: ({ cell: { value }, index }) => (
      //     <Table.Cell key={index}>{value}</Table.Cell>
      //   ),
      // },
      {
        Header: "Payment status",
        accessor: "payment_status",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index}>{decideStatus(value)}</Table.Cell>
        ),
      },
      {
        Header: "Payment method",
        accessor: "payments.0.provider_id",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index}>{value}</Table.Cell>
        ),
      },
      {
        Header: "New/Renew",
        accessor: "payments.0.data",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index}>
            {value?.notification?.renewalInfo ||
            value?.description === "Subscription update" ||
            value?.description === "GPA Renew"
              ? "renew"
              : "new"}
          </Table.Cell>
        ),
      },
      {
        Header: "SKU",
        accessor: "items.0.variant.sku",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index}>{value}</Table.Cell>
        ),
      },
      {
        Header: "Qty",
        accessor: "items.0.quantity",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index}>{value}</Table.Cell>
        ),
      },
      {
        Header: () => <div className="text-right">Total</div>,
        accessor: "refundable_amount",
        Cell: ({ row, cell: { value }, index }) => (
          <Table.Cell key={index}>
            <div className="text-right">
              {formatAmountWithSymbol({
                amount: value,
                currency: row.original.currency_code,
                digits: 2,
              })}
            </div>
          </Table.Cell>
        ),
      },
      // {
      //   Header: "",
      //   accessor: "currency_code",
      //   Cell: ({ cell: { value }, index }) => (
      //     <Table.Cell key={index} className="w-[5%]">
      //       <div className="text-right text-grey-40">{value.toUpperCase()}</div>
      //     </Table.Cell>
      //   ),
      // },
      // {
      //   Header: "",
      //   accessor: "country_code",
      //   Cell: ({ row, index }) => (
      //     <Table.Cell className="w-[5%] pr-2" key={index}>
      //       <div className="flex rounded-rounded w-full justify-end">
      //         <Tooltip
      //           content={
      //             isoAlpha2Countries[
      //               row.original.shipping_address.country_code.toUpperCase()
      //             ] || row.original.shipping_address.country_code.toUpperCase()
      //           }
      //         >
      //           <ReactCountryFlag
      //             className={"rounded"}
      //             svg
      //             countryCode={row.original.shipping_address.country_code}
      //           />
      //         </Tooltip>
      //       </div>
      //     </Table.Cell>
      //   ),
      // },
    ],
    []
  )

  return [columns]
}

export default useOrderTableColums
