import React, { useEffect, useState } from "react"
import BodyCard from "../../../../components/organisms/body-card"
import { useProductForm } from "../form/product-form-context"
import { useAdminProductTypes } from "medusa-react"
import Checkbox from "../../../../components/atoms/checkbox"
import Button from "../../../../components/fundamentals/button"
import Input from "../../../../components/molecules/input"
import PlusIcon from "../../../../components/fundamentals/icons/plus-icon"
import TrashIcon from "../../../../components/fundamentals/icons/trash-icon"
import IconTooltip from "../../../../components/molecules/icon-tooltip"

const SubscriptionDescriptions = ({ product }) => {
  const { register, metadata, setMetadata } = useProductForm()

  // const aaa = useProductForm();
  // window.aaa = aaa;

  const [descriptions, setDescriptions] = useState(() => {
    if (product.metadata && product.metadata.descriptions) {
      return product.metadata.descriptions
    }
    return [""]
  })

  const [recommended, setRecommended] = useState(() => {
    if (product.metadata && product.metadata.recommended) {
      return product.metadata.recommended
    }
    return false
  })

  const updateMetadataDescription = (descriptions) => {
    metadata.descriptions = descriptions
    setMetadata(metadata)
  }

  const appendDescription = () => {
    const newDescriptions = [...descriptions, ""]
    setDescriptions(newDescriptions)
    updateMetadataDescription(newDescriptions)
  }

  const deleteDescription = (index) => {
    descriptions.splice(index, 1)
    const newDescriptions = [...descriptions]
    setDescriptions(newDescriptions)
    updateMetadataDescription(newDescriptions)
  }

  const handleDescriptionChange = (value, index) => {
    descriptions[index] = value
    const newDescriptions = [...descriptions]
    setDescriptions(newDescriptions)
    updateMetadataDescription(newDescriptions)
  }

  const handleRecommendedChange = (event) => {
    const value = event.target.checked
    setRecommended(value)
    metadata.recommended = value
    setMetadata(metadata)
  }

  return (
    <>
      <h6 className="inter-base-semibold mb-1">Subscription Plan Related</h6>
      <div>
        {descriptions.map((option, index) => (
          <div key={index} className="w-full flex">
            <div className="w-full mt-4 grid medium:grid-cols-2 grid-cols-1 gap-y-base gap-x-xsmall">
              <Input
                name={`descriptions${index}`}
                label={`Subscription Plan Description Row ${index + 1}`}
                placeholder="Placeholder"
                value={option}
                onChange={(event) => {
                  handleDescriptionChange(event.target.value, index)
                }}
                ref={register}
              />
              <button>
                <TrashIcon
                  size={20}
                  onClick={() => deleteDescription(index)}
                  className="text-grey-40"
                />
              </button>
            </div>
          </div>
        ))}

        <div className="mt-large mb-small">
          <Button
            onClick={appendDescription}
            type="button"
            variant="ghost"
            size="small"
          >
            <PlusIcon size={20} />Add Row
          </Button>
        </div>

        {/* <div className="flex item-center gap-x-1.5 mb-xlarge">
          <Checkbox
            name="recommended"
            label="Recommended Plan"
            checked={recommended}
            onChange={handleRecommendedChange}
            ref={register}
          />
          <IconTooltip content={"Display Recommended Label"} />
        </div> */}
      </div>
    </>
  )
}

export default SubscriptionDescriptions
