import React from "react"
import Textarea from "../../../../components/molecules/textarea"
import BodyCard from "../../../../components/organisms/body-card"
import { useProductForm } from "../form/product-form-context"
import { Controller } from "react-hook-form"
import Datetime from "react-datetime"
import "react-datetime/css/react-datetime.css"
import moment from "moment"
import "moment/locale/zh-hk"

const Misc = () => {
  const { register, control } = useProductForm()
  return (
    <BodyCard title="Miscellaneous">
      <Textarea
        name="email_extra_info"
        id="email_extra_info"
        label="Email Extra Info"
        placeholder="Extra Info to be added to the email sent to customer..."
        rows={8}
        ref={register}
      />
      {/* <input type="datetime-local" name="start_date" ref={register} /> */}
      <div className="h-[300px] flex gap-x-6 mt-4">
        <div className="">Selling Period</div>
        <div className="flex flex-col mr-[100px]">
          <div>Start Date</div>{" "}
          <Controller
            control={control}
            name="start_date"
            render={({ value, onChange }) => {
              return (
                <Datetime
                  value={value ? moment(value) : value}
                  // onChange={(v) => {
                  //   if (v && onChange) {
                  //     if (typeof v === "string") {
                  //       const m = moment(v)
                  //       if (m.isValid()) {
                  //         onChange(moment(v).toISOString())
                  //       } else {
                  //         onChange(null)
                  //       }
                  //     } else {
                  //       onChange(v?.toISOString())
                  //     }
                  //   } else {
                  //     onChange(null)
                  //   }
                  // }}
                  onChange={(v) => {
                    onChange(v)
                  }}
                  inputProps={{
                    className: "border border-green-500",
                  }}
                  open={true}
                  locale="zh-hk"
                />
              )
            }}
          />
        </div>
        <div className="flex flex-col">
          <div>Expiry Date</div>{" "}
          <Controller
            control={control}
            name="expiry_date"
            render={({ value, onChange }) => {
              return (
                <Datetime
                  value={value ? moment(value) : value}
                  // onChange={(v) => {
                  //   if (v && onChange) {
                  //     if (typeof v === "string") {
                  //       const m = moment(v)
                  //       if (m.isValid()) {
                  //         onChange(moment(v).toISOString())
                  //       } else {
                  //         onChange(null)
                  //       }
                  //     } else {
                  //       onChange(v?.toISOString())
                  //     }
                  //   } else {
                  //     onChange(null)
                  //   }
                  // }}
                  onChange={(v) => {
                    onChange(v)
                  }}
                  inputProps={{
                    className: "border border-green-500",
                  }}
                  open={true}
                  locale="zh-hk"
                />
              )
            }}
          />
        </div>
      </div>
    </BodyCard>
  )
}

export default Misc
