import React, { useState } from "react"
import { Controller, useFieldArray } from "react-hook-form"
import Input from "../../../../components/molecules/input"
import Button from "../../../../components/fundamentals/button"

export const ArrayInput = ({ control, index, register }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `surveys[0].questions[${index}].options`,
  })

  return (
    <>
      <div className="flex gap-4 items-center mb-2">
        <h6>Options for Dropdown List</h6>
        <Button
          size="small"
          variant="primary"
          onClick={() => {
            append({
              value: "",
            })
          }}
        >
          add option
        </Button>
      </div>
      <div className="w-full">
        {fields.map((field, i) => {
          return (
            <div key={field.id} className="flex mb-2 gap-x-2">
              <input
                className="w-full px-2"
                // label="Option"
                placeholder="Enter Option"
                name={`surveys[0].questions[${index}].options[${i}].value`}
                ref={register({ required: true })}
                // ref={register()}
                defaultValue={field.value}
              />
              <Button
                variant="danger"
                size="small"
                onClick={() => {
                  remove(i)
                }}
              >
                remove option
              </Button>
            </div>
          )
        })}
      </div>
    </>
  )
}
