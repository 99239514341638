import React from "react"
import * as RadixAvatar from "@radix-ui/react-avatar"
import clsx from "clsx"
import Spinner from "../../components/atoms/spinner"

type logoImageProps = {
  img: string
  name: string
  handleClick: any
  loading: boolean
  font?: string
  color?: string
}

const logoImage: React.FC<logoImageProps> = ({
  img,
  name,
  handleClick,
  loading,
  font = "inter-small-semibold",
  color = "bg-violet-60",
}) => {
  return (
    <div>
      <div className="inter-base-semibold text-center">{name}</div>
      <div
        onClick={handleClick}
        className="w-28 h-28 p-2 mt-2 flex items-center justify-center rounded-rounded hover:bg-grey-5 cursor-pointer rounded"
      >
        {loading && (
          <div className="z-10 absolute justify-center items-center">
            <Spinner variant="secondary" />
          </div>
        )}
        <div
          className={clsx("w-full h-full transition-opacity", {
            "opacity-50": loading,
          })}
        >
          <RadixAvatar.Root
            className={clsx(
              "w-full h-full items-center justify-center overflow-hidden select-none",
              color
            )}
          >
            <RadixAvatar.Image
              src={img}
              alt={name}
              className="w-full h-full object-cover rounded"
            />
            <RadixAvatar.Fallback
              className={clsx(
                "w-full h-full flex items-center justify-center bg-inherit text-grey-0 rounded",
                font
              )}
            >
              Choose Image
            </RadixAvatar.Fallback>
          </RadixAvatar.Root>
        </div>
      </div>
    </div>
  )
}

export default logoImage
